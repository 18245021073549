<div *ngIf="showNewsPopup" class="backdrop"></div>
<app-news *ngIf="showNewsPopup" class="newspopup">
  <div class="top-row">
    <button (click)="closeNews()" class="row-btn">
      <span>&times;</span>
    </button>
  </div>
  <div class="head-cont">
    <div class="info-heading">{{selectedNews.source_name}} - {{selectedNews.date | date:'d/M/yy'}}</div>
    <div class="news-heading">{{selectedNews.title}}</div>
  </div>
  <div class="descrp">{{selectedNews.text}}</div>
  <div class="text-center">
    <a [href]="selectedNews.url" target="_blank" class="btn btn-primary">Continue to Full
      Article</a>
    <a class="btn btn-dark" (click)="closeNews()" [routerLink]="['/company-profile', selectedNews?.symbol]">See more
      news about {{symbol}}
    </a>
  </div>
  <div class="nws_in_adss">
  </div>
</app-news>

<div *ngIf="newsfilterpopup" class="backdrop"></div>
<app-news *ngIf="newsfilterpopup" class="publispopup newwatch_pop com_profilenews">
  <div class="jw-modal">
    <div class="jw-modal-body">
      <div class="modal_header">
        <h2>Select Publishers</h2>
        <button (click)="closenewsfilter()" class="close">
          <span>&times;</span>
        </button>
      </div>
      <div class="pubfl_serach">
        <button type="button" class="btn"><i class="fa fa-search" aria-hidden="true"></i></button>
        <input type="text" placeholder="Search Publisher" [(ngModel)]="searchText" class="form-control">
      </div>
      <div class="text-right"> <span class="reset_filbtn" (click)="resetfilter(1)"><i class="fa fa-repeat"
            aria-hidden="true"></i> Reset Selection</span></div>
      <div class="publrs_datawarp">
        <ng-container *ngFor="let newsItem of this.Publicerlist | filter:searchText">
          <div class="publrs_dataitem"
            *ngIf="newsItem?.source_name?.search(this.search_symbol) >= 0 && newsItem?.source_name">
            <label class="switch">
              <input type="checkbox" (change)="filternews($event,newsItem?.source_name)"
                [checked]="Filterbynews.indexOf(newsItem?.source_name) >= 0" />
              <span class="slider round"></span>
            </label>
            <span>
              {{newsItem?.source_name}}
            </span>
          </div>
        </ng-container>
      </div>
      <div class="model_footer">
        <button type="button" data-dismiss="modal" aria-label="Close" (click)="applynewsfilters()"
          class="btn btn-primary">Apply Filters</button>
        <button type="button" data-dismiss="modal" aria-label="Close" (click)="closenewsfilter()"
          class="btn btn-light">Cancel</button>
      </div>
    </div>
  </div>
</app-news>

<div class="main_warper container">
  <div class="row justify-content-center">
    <div class="main-content col-md-12">
      <div class="page-content">
        <div class="row">
          <!-- Yahoo Api Data Show Start -->
          <div class="col-md-8 cp_info" *ngIf="SymbolSocketdata?.companyName !== null && yahoodataShow">
            <div class="cpinfo_head">
              <div
                [ngClass]="this.company_quote_data?.changePercent >0 ? 'cp_name clearfix' : this.company_quote_data?.changePercent < 0 ? 'clearfix cp_name cp_down' : 'clearfix cp_name cp_defult' ">
                <div class="com_title">
                  <h1><small *ngIf="this.News_data.length == 0"
                      style="position: relative;bottom: 6px;">^</small>{{this.SymbolSocketdata?.symbol?.symbol
                    ?this.SymbolSocketdata?.symbol?.symbol:'N/A'}}</h1>
                  <span>{{this.SymbolSocketdata?.companyName ? this.SymbolSocketdata?.companyName:'N/A'}}</span>
                </div>
                <ul class="pm_cpinfo pre_posttext" *ngIf="SymbolSocketdata2?.is_market_open == false && SymbolSocketdata2?.pre_market_open == false && SymbolSocketdata2?.post_market_open == false && this.News_data.length > 0">
                  <li
                    [ngClass]="{'text-success': (SymbolSocketdata?.postMarketChangePercent >  0), 'text-warning': (SymbolSocketdata?.postMarketChangePercent <  0)}">
                    {{SymbolSocketdata?.postMarketChangePercent > 0 ? '+' : ''
                    }}{{(SymbolSocketdata?.postMarketChangePercent ||
                    SymbolSocketdata?.postMarketChangePercent == 0) ? ((SymbolSocketdata?.postMarketChangePercent) |
                    number:'.2-2')+'%':"N/A" }}
                  </li>
                  <li
                    [ngClass]="{'text-success': (SymbolSocketdata?.postMarketChange >  0), 'text-warning': (SymbolSocketdata?.postMarketChange <  0)}">
                    {{SymbolSocketdata?.postMarketChange > 0 ? '$' : '$' }}{{(SymbolSocketdata?.postMarketChange ||
                    SymbolSocketdata?.postMarketChange == 0) ? ((SymbolSocketdata?.postMarketChange) |
                    number : '.2-2' ):"N/A" }}
                  </li>
                  <li>
                    <small>Post-Market</small>
                    <p>
                      {{SymbolSocketdata?.postMarketPrice? "$"+ (SymbolSocketdata?.postMarketPrice | number :
                      '.2-2'):'N/A'}}</p>
                  </li>
                </ul>
                <!-- Post Market ul End -->

                <!-- Post Market true Start -->
                <ul class="pm_cpinfo pre_posttext" *ngIf="SymbolSocketdata2?.post_market_open == true && this.News_data.length > 0">
                  <li
                    [ngClass]="{'text-success': (SymbolSocketdata?.postMarketChangePercent >  0), 'text-warning': (SymbolSocketdata?.postMarketChangePercent <  0)}">

                    {{SymbolSocketdata?.postMarketChangePercent > 0 ? '+' : ''
                    }}{{(SymbolSocketdata?.postMarketChangePercent ||
                    SymbolSocketdata?.postMarketChangePercent == 0) ? ((SymbolSocketdata?.postMarketChangePercent) |
                    number:'.2-2')+'%':"N/A"}}
                  </li>
                  <li
                    [ngClass]="{'text-success': (SymbolSocketdata?.postMarketChange >  0), 'text-warning': (SymbolSocketdata?.postMarketChange <  0)}">
                    {{SymbolSocketdata?.postMarketChange > 0 ? '$' : '$' }}{{(SymbolSocketdata?.postMarketChange ||
                    SymbolSocketdata?.postMarketChange == 0) ? ((SymbolSocketdata?.postMarketChange) |
                    number : '.2-2' ):"N/A" }}
                  </li>
                  <li>
                    <small>Post-Market</small>
                    <p>
                      {{SymbolSocketdata?.postMarketPrice? "$"+ (SymbolSocketdata?.postMarketPrice | number :
                      '.2-2'):'N/A'}}</p>
                  </li>
                </ul>
                <!-- Post Market true End -->

                <!-- Pre Market ul Start -->
                <ul class="pm_cpinfo pre_posttext" *ngIf="SymbolSocketdata2?.is_market_open == false && SymbolSocketdata2?.pre_market_open == true && this.News_data.length > 0">
                  <li
                    [ngClass]="{'text-success': (SymbolSocketdata?.preMarketChangePercent >  0), 'text-warning': (SymbolSocketdata?.preMarketChangePercent <  0)}">
                    {{SymbolSocketdata?.preMarketChangePercent > 0 ? '+' : ''
                    }}{{(SymbolSocketdata?.preMarketChangePercent ||
                    SymbolSocketdata?.preMarketChangePercent == 0) ? ((SymbolSocketdata?.preMarketChangePercent) |
                    number:'.2-2')+'%':"N/A" }}
                  </li>
                  <li
                    [ngClass]="{'text-success': (SymbolSocketdata?.preMarketChange >  0), 'text-warning': (SymbolSocketdata?.preMarketChange <  0)}">
                    {{SymbolSocketdata?.preMarketChange > 0 ? '$' : '$' }}{{(SymbolSocketdata?.preMarketChange ||
                    SymbolSocketdata?.preMarketChange == 0) ? ((SymbolSocketdata?.preMarketChange) |
                    number : '.2-2' ):"N/A" }}
                  </li>
                  <li>
                    <small>Pre-Market</small>
                    <p>{{SymbolSocketdata?.preMarketPrice? "$"+ (SymbolSocketdata?.preMarketPrice | number :
                      '.2-2'):'N/A'}}</p>
                  </li>
                </ul>
                <!-- Pre Market ul End -->
              </div>
            </div>
            <div class="cp_foot">
              <div class="cp_leftpanel text-left">
                <strong>Vol: {{this.company_quote_data?.latestVolume
                  ?(this.company_quote_data?.latestVolume | AmountFormat: 2):'N/A'}} </strong>
                <span class="space_arrow"><strong>|</strong> </span>
                <strong>Avg: {{this.company_quote_data?.avgTotalVolume
                  ?(this.company_quote_data?.avgTotalVolume | AmountFormat: 2):'N/A'}} </strong>
                <h2
                  [ngClass]="{'text-success animate__bounceInDown': this.company_quote_data?.changePercent>0, 'text-warning': this.company_quote_data?.changePercent<0 ,'text-dark': this.company_quote_data?.changePercent==0}"
                  *ngIf="this.News_data.length > 0">
                    {{ (this.company_quote_data?.changePercent || this.company_quote_data?.changePercent == 0) ?
                      (this.company_quote_data?.changePercent > 0 ? '+' + (this.company_quote_data?.changePercent | number : '.2-2') :
                      (this.company_quote_data?.changePercent | number : '.2-2')) + '%' : 'N/A' }}
                </h2>
                <h2 [ngClass]="{'text-success animate__bounceInDown': this.company_quote_data?.changePercent>0, 'text-warning': this.company_quote_data?.changePercent<0 ,'text-dark': this.company_quote_data?.changePercent==0}" *ngIf="this.News_data.length == 0">
                  {{this.company_quote_data?.changePercent > 0? '': ''}} {{this.company_quote_data?.changePercent > 0?
                  '+': ''}} {{(this.company_quote_data?.changePercent || this.company_quote_data?.changePercent == 0) ?
                  ((this.company_quote_data?.changePercent ) | number : '1.2-2' ): 'N/A'}}%
                </h2>
                <div class="watch_btnpanel" *ngIf="this.News_data.length > 0">
                  <div class="add_watltbtn" *ngIf="model?.symbol?.id">
                    <button type="button" class="btn btn-outline-success">
                      <i (click)="toggleWatchlist()" class="fa fa-plus-square pr-2" aria-hidden="true"></i>
                    </button>
                    <ul class="watchlistname_list" *ngIf="openOption && watchlistname.length > 0">
                      <li *ngFor="let list of watchlistname; let i = index" (click)="savenewwatchlist(list.slug)">
                        <a href="javascript:void(0);">{{list.name}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="cp_rightpanel">
                <div class="mcpt" *ngIf="this.News_data.length > 0">
                  <strong>MC: {{this.company_quote_data?.marketCap
                    ?(this.company_quote_data?.marketCap|AmountFormat: 2):'N/A'}}
                  </strong>
                  <span class="space_arrow"><strong>|</strong> </span>
                  <strong>
                    P/E: {{this.company_quote_data?.peRatio?this.company_quote_data?.peRatio.toFixed(2) : 'N/A' }}
                  </strong>
                </div>
                <strong
                  [ngClass]="{'text-success': this.company_quote_data?.changePercent>0, 'text-warning': this.company_quote_data?.changePercent<0 , 'text-dark': this.company_quote_data?.changePercent==0 }">
                  {{this.company_quote_data?.change > 0? '$+' : '$'}}{{this.company_quote_data ?
                  (this.company_quote_data?.change | number : '.2-2'):"N/A" }}
                </strong>
                <h4>
                  ${{this.company_quote_data?.latestPrice ? (this.company_quote_data?.latestPrice | number :
                  '.2-2'):'N/A'}}
                </h4>
              </div>
            </div>
          </div>
          <!-- yahoo api data show end -->

          <!-- yahoo websocket data show start -->
          <div class="col-md-8 cp_info" *ngIf="SymbolSocketdata?.companyName !== null && yahoodataShow2">
            <div class="cpinfo_head">
              <div class="company_header">
                <div class="com_title">
                  <h1><small *ngIf="this.News_data.length == 0"
                      style="position: relative;bottom: 6px;">^</small>{{this.SymbolSocketdata?.symbol?.symbol
                    ?this.SymbolSocketdata?.symbol?.symbol:'N/A'}}</h1>
                  <span>{{this.SymbolSocketdata?.companyName ? this.SymbolSocketdata?.companyName:'N/A'}}</span>
                </div>
                <ul class="pm_cpinfo pre_posttext" *ngIf="messages?.marketHours == '2'">
                  <li
                    [ngClass]="{'text-success': (this.messages?.changePercent >  0), 'text-warning': (this.messages?.changePercent <  0)}">
                      {{this.messages?.changePercent > 0 ? '+' : ''}}{{(this.messages?.changePercent || this.messages?.changePercent == 0) ? ((this.messages?.changePercent) | number:'.2-2')+'%':"N/A" }}
                  </li>
                  <li
                    [ngClass]="{'text-success': (this.messages?.change >  0), 'text-warning': (this.messages?.change <  0)}">
                    {{this.messages?.change > 0 ? '$' : '$' }}{{(this.messages?.change ||
                      this.messages?.change == 0) ? ((this.messages?.change) |
                    number : '.2-2' ):"N/A" }}
                  </li>
                  <li>
                    <small>Post-Market</small>
                    <p>
                      {{this.messages?.price ? "$"+ (this.messages?.price | number :
                      '.2-2'):'N/A'}}</p>
                  </li>
                </ul>
                <!-- Post Market ul End -->

                <!-- Pre Market ul Start -->
                <ul class="pm_cpinfo pre_posttext" *ngIf="messages?.marketHours == '0' && this.News_data.length > 0">
                  <li
                    [ngClass]="{'text-success': (this.messages?.changePercent >  0), 'text-warning': (this.messages?.changePercent <  0)}">
                      {{this.messages?.changePercent > 0 ? '+' : ''}}{{(this.messages?.changePercent || this.messages?.changePercent == 0) ? ((this.messages?.changePercent) | number:'.2-2')+'%':"N/A" }}
                  </li>
                  <li
                    [ngClass]="{'text-success': (this.messages?.changePercent >  0), 'text-warning': (this.messages?.changePercent <  0)}">
                    {{this.messages?.change > 0 ? '$' : '$' }}{{(this.messages?.change ||
                      this.messages?.change == 0) ? ((this.messages?.change) |
                    number : '.2-2' ):"N/A" }}
                  </li>
                  <li>
                    <small>Pre-Market</small>
                    <p>
                      {{this.messages?.price ? "$"+ (this.messages?.price | number :
                      '.2-2'):'N/A'}}</p>
                  </li>
                </ul>
                <!-- Pre Market ul End -->
              </div>
            </div>
            
            <div class="cp_foot">
              <div *ngIf="this.messages?.marketHours != '1'" [ngClass]="this.company_quote_data?.changePercent >0 ? 'company_data company_data01 ' : this.company_quote_data?.changePercent < 0 ? 'company_data company_data02' : 'company_data company_data03' "></div>
              <div *ngIf="this.messages?.marketHours == '1'" [ngClass]="this.messages?.changePercent >0 ? 'company_data company_data01 ' : this.messages?.changePercent < 0 ? 'company_data company_data02' : 'company_data company_data03' "></div>
              <div class="cp_leftpanel text-left">
                  <strong *ngIf="this.messages?.marketHours != '1'">Vol: {{this.yahooapiData?.latestVolume
                    ?(this.yahooapiData?.latestVolume | AmountFormat: 2):'N/A'}}
                  </strong>
                  <strong *ngIf="this.messages?.marketHours == '1'">Vol: 
                    {{this.messages?.dayVolume ?(this.messages?.dayVolume |AmountFormat: 2):'N/A'}} 
                  </strong>
                  <span class="space_arrow"><strong>|</strong> </span>
                  <strong>Avg: {{this.company_quote_data?.avgTotalVolume
                    ?(this.company_quote_data?.avgTotalVolume |AmountFormat: 2):'N/A'}} </strong>
                <h2
                  [ngClass]="{'text-success animate__bounceInDown': this.yahooapiData?.changePercent>0, 'text-warning': this.yahooapiData?.changePercent<0 ,'text-dark': this.yahooapiData?.changePercent==0}"
                  *ngIf="this.News_data.length > 0 && this.messages?.marketHours != '1'">
                  {{(this.yahooapiData?.changePercent || this.yahooapiData?.changePercent == 0) ?
                  (this.yahooapiData?.changePercent>0 ? '+' + ((this.yahooapiData?.changePercent)|
                  number : '1.2-2') : (this.yahooapiData?.changePercent) | number : '1.2-2')+'%':'N/A'}}
                </h2>
                <h2
                  [ngClass]="{'text-success animate__bounceInDown': this.messages?.changePercent>0, 'text-warning': this.messages?.changePercent<0 ,'text-dark': this.messages?.changePercent==0}"
                  *ngIf="this.News_data.length > 0 && this.messages?.marketHours == '1'">
                  {{ (this.messages?.changePercent || this.messages?.changePercent == 0) ?
                    (this.messages?.changePercent > 0 ? '+' + (this.messages?.changePercent | number : '1.2-2') :
                    (this.messages?.changePercent | number : '1.2-2')) + '%' : 'N/A' }}
                </h2>
                <h2 [ngClass]="{'text-success animate__bounceInDown': this.company_quote_data?.changePercent>0, 'text-warning': this.company_quote_data?.changePercent<0 ,'text-dark': this.company_quote_data?.changePercent==0}" *ngIf="this.News_data.length == 0">
                  {{this.company_quote_data?.changePercent > 0? '': ''}} {{this.company_quote_data?.changePercent > 0?
                  '+': ''}} {{(this.company_quote_data?.changePercent || this.company_quote_data?.changePercent == 0) ?
                  ((this.company_quote_data?.changePercent ) | number : '1.2-2' ): 'N/A'}}%
                </h2>
                <div class="watch_btnpanel" *ngIf="this.News_data.length > 0">
                  <div class="add_watltbtn" *ngIf="model?.symbol?.id">
                    <button type="button" class="btn btn-outline-success">
                      <i (click)="toggleWatchlist()" class="fa fa-plus-square pr-2" aria-hidden="true"></i>
                    </button>
                    <ul class="watchlistname_list" *ngIf="openOption && watchlistname.length > 0">
                      <li *ngFor="let list of watchlistname; let i = index" (click)="savenewwatchlist(list.slug)">
                        <a href="javascript:void(0);">{{list.name}}</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="cp_rightpanel">
                <div class="mcpt" *ngIf="this.News_data.length > 0">
                  <strong>MC: {{this.company_quote_data?.marketCap
                    ?(this.company_quote_data?.marketCap|AmountFormat: 2):'N/A'}}
                  </strong>
                  <span class="space_arrow"><strong>|</strong> </span>
                  <strong>
                    P/E: {{this.company_quote_data?.peRatio?this.company_quote_data?.peRatio.toFixed(2) : 'N/A' }}
                  </strong>
                </div>
                <strong
                  [ngClass]="{'text-success': this.yahooapiData?.change>0, 'text-warning': this.yahooapiData?.change<0 , 'text-dark': this.yahooapiData?.change==0 }" *ngIf="this.messages?.marketHours != '1'">
                  <!-- {{this.SymbolSocketdata?.change
                  ?( this.SymbolSocketdata?.changePercent>0 ? '+' + this.SymbolSocketdata?.change :
                  this.SymbolSocketdata?.change | number : '.2-3'):'N/A'}} -->
                  {{this.yahooapiData?.change > 0? '$+' : '$'}}{{this.yahooapiData ?
                  (this.yahooapiData?.change | number : '.2-2'):"N/A" }}
                </strong>
                <strong [ngClass]="{'text-success': this.messages?.changePercent>0, 'text-warning': this.messages?.changePercent<0 , 'text-dark': this.messages?.changePercent==0 }" *ngIf="this.messages?.marketHours == '1'">
                  {{this.messages?.change > 0 ? '$+' : '$'}}{{this.messages?.change ?
                  (this.messages?.change | number : '.2-2'):"N/A" }}
                </strong>
                <h4 *ngIf="this.messages?.marketHours != '1'">
                  ${{this.yahooapiData?.latestPrice ? (this.yahooapiData?.latestPrice | number :
                  '.2-2'):'N/A'}}
                </h4>
                <h4 *ngIf="this.messages?.marketHours == '1'">
                  ${{this.messages?.price ? (this.messages?.price | number :
                  '.2-2'):'N/A'}}
                </h4>
                <!-- <h4>
                  ${{this.SymbolSocketdata?.latestPrice ?
                  addCommaFilter (this.SymbolSocketdata?.latestPrice):'N/A'}}</h4> -->
              </div>
            </div>
          </div>
          <!-- yahoo websocket data show end -->
          <div class="col-md-8 cp_info" *ngIf="SymbolSocketdata?.companyName == null">
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <div class="col-md-4 cp_rightsec"
            [ngClass]="this.modelsym?.symbol == 'ES1!' || this.modelsym?.symbol == 'YM1!' || this.modelsym?.symbol == 'NQ1!' || this.modelsym?.symbol == 'RTY1!' || this.modelsym?.symbol == 'US10Y' || this.modelsym?.symbol == 'DJI (DJ-DJI/)' || this.modelsym?.symbol == 'RUT' || this.modelsym?.symbol == 'US30Y' || this.modelsym?.symbol == 'US05Y' || this.modelsym?.symbol == '^IRX'|| this.modelsym?.symbol == '^DJT' || this.modelsym?.symbol == '^MID' ?'d-none':''">
            <div class="cp_chart xs-hide">
              <!-- TradingView Widget BEGIN -->
              <div class="tradingview-widget-container">
                <div id="tradingview_ceb0f"></div>
              </div>
              <!-- TradingView Widget END -->
            </div>
          </div>
          <div class="col-md-8" id="full_section" [ngClass]="this.News_data.length > 0 ? 'class1' : 'd-none' ">
            <div id="balanceSheet1">
              <ul class="nav nav-tabs w33" id="myTab" role="tablist">
                <li class="nav-item" (click)="pageshow(1)">
                  <a class="nav-link active" id="news-tab" data-toggle="tab" href="#news" role="tab"
                    aria-controls="news" aria-selected="true">News</a>
                </li>
                <li class="nav-item" (click)="pageshow(2)">
                  <a class="nav-link" id="numbers-tab" data-toggle="tab" href="#numbers" role="tab"
                    aria-controls="numbers" aria-selected="false">numbers</a>
                </li>
                <li class="nav-item" (click)="pageshow(3)">
                  <a class="nav-link" id="financials-tab" data-toggle="tab" href="#financials" role="tab"
                    aria-controls="financials" aria-selected="false">Financials</a>
                </li>
                <li class="nav-item" (click)="pageshow(4)">
                  <a class="nav-link" id="about-tab" data-toggle="tab" href="#about" role="tab" aria-controls="about"
                    aria-selected="false">About</a>
                </li>
                <li class="nav-item lg-hide" (click)="pageshow(5)">
                  <a class="nav-link" id="xschart-tab" data-toggle="tab" href="#chart" role="tab" aria-controls="chart"
                    aria-selected="false">Chart</a>
                </li>
              </ul>
              <div class="tab-content cp_profiletab_ct" id="myTabContent">
                <div class="tab-pane fade show active" id="news" role="tabpanel" aria-labelledby="news-tab"
                  *ngIf="this.parentTabValue == 1">
                  <div class="row news_subhead news_searching">
                    <div class="col-md-4">
                      <div class="form-group news_seraching">
                        <button type="button" class="sr_btn"><i class="fa fa-search"></i></button>
                        <input type="text" class="form-control" [(ngModel)]="searchNews"
                          (keyup.enter)="searchNewsFilter($event.target.value)"
                          placeholder="Search {{this.SymbolSocketdata?.symbol?.symbol ?this.SymbolSocketdata?.symbol?.symbol:'N/A'}} News">
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="datepicker btn-icon btn" mat-raised-button (click)="picker.open()">
                        <img src="assets/img/SVG/datepicker_icon.svg" class="svg_icon" alt="">
                        <input #toInput matInput [matDatepicker]="picker" readonly hidden
                          (dateChange)="datechange($event)" [ngStyle]="{'width': datefilter ? '75px' : '0px'}">
                        <mat-datepicker #picker></mat-datepicker>
                      </div>
                      <a class="btn btn-icon" href="javascript:void(0);" (click)="opennewsfilter()">
                        <img src="assets/img/SVG/filter_icon.svg" class="svg_icon" alt="">
                      </a>
                    </div>
                  </div>
                  <div class="latest_news m-0 ptb">
                    <ul>
                      <ng-container *ngFor="let newsItem of this.News_data; let i=index;">
                        <li title="{{ newsItem?.title ? newsItem?.title :''}} ">
                          <small class="news_date xs-hide">{{(i==0) || (this.News_data[i-1]?.date?.replace('
                            00:00:00','')!=newsItem?.date?.replace(' 00:00:00',''))?
                            filterDate(newsItem?.date?.replace(' 00:00:00',''),false
                            ):""}}</small>
                          <small class="news_date lg-hide">{{filterDate(newsItem?.date?.replace('
                            00:00:00',''),true)}}</small>
                          <small class="news_time">
                            {{newsItem?.time?.replace('1900-01-01',"").substring(0,newsItem?.time?.replace('1900-01-01',"").length
                            - 3)}}
                          </small>
                          <small class="comp_logo lg-hide">{{newsItem?.source_name}}</small>
                          <p class="news_dicription">
                            <a class="color" href="javascript:void(0)"
                              (click)="gotoNews(newsItem)">{{newsItem?.title}}</a>
                            <small class="comp_logo xs-hide">{{newsItem?.source_name}}</small>
                          </p>
                        </li>
                      </ng-container>
                    </ul>
                    <div *ngIf="this.News_data.length == 0" class="datanotfound">
                      <span>News not available for this symbol </span>
                    </div>
                    <div class="pagenation_warp">
                      <ul *ngIf="demoarray.length > 0" class="pagenation">
                        <li *ngIf="pageselect > 1" (click)="pageChange(pageselect-1)">prev</li>
                        <ng-container *ngFor="let val of demoarray; let i = index;">
                          <span *ngIf="valuecheck(val,demoarray[i-1])">...</span>
                          <li (click)="pageChange(val)" [ngClass]="val == pageselect ? 'active':'' ">{{val}}</li>
                        </ng-container>
                        <li *ngIf="pageselect != pagenum" (click)="pageChange(pageselect+1)">next</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="numbers" role="tabpanel" aria-labelledby="numbers-tab"
                  *ngIf="this.parentTabValue == 2">
                  <div class="row news_subhead mt-1 mb-1 number_searching">
                    <div class="col-md-5">
                      <div class="form-group news_seraching newicon" id="search-bar">
                        <button type="button" class="sr_btn"><i class="fa fa-search"></i></button>
                        <form (ngSubmit)="getSearchData()" *ngIf="isShowSearchBar">
                          <div class="form-group">
                            <ng-select #ngSelectSearch class="form-control search-bar" bindLabel="name"
                              placeholder="Compare Symbol" [searchable]="true" [ngModelOptions]="{standalone: true}"
                              (search)="searchValue=$event?.term;getSearchData()" [clearable]="true"
                              [(ngModel)]="searchValue" (blur)="isOpenSearchBar=true;" (clear)="onClear()"
                              clearAllText="" (keyup.enter)="comparebalance(searchValue)"
                              (click)="comparebalance(searchValue)">
                              <ng-container *ngIf="searchValue">
                                <ng-option [value]="item.value" *ngFor="let item of select2Model">
                                  <img
                                    [src]="item.changePercent > 0 ? '../../assets/img/market_up.png' : item.changePercent < 0 ? '../../assets/img/market_down.png' : '../../assets/img/no_dataupdate.png'" alt="">
                                  <a class="a-no-decore" (click)="comparebalance(item?.value)"
                                    (keyup.enter)="comparebalance(item?.value)">
                                    <strong>{{item?.value.symbol}}</strong><small>{{item.label}}</small>
                                  </a>
                                </ng-option>
                              </ng-container>
                            </ng-select>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>

                  <ng-container>
                    <div class="data_numberbox" [ngClass]="{'desktop-numberview' : this.numsymbol == null}"
                      *ngFor="let itemnumber of getNumberedata;let i=index">
                      <div class="data_numbercell">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="listdata">
                              <h5>Valuation</h5>
                              <ul>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/M/market-capitalization-market-cap">
                                    Market Cap
                                  </a>
                                  <span class="total_value" *ngIf="companynumbersShow">
                                    {{itemnumber?.MarketCap? '$'+(itemnumber?.MarketCap | AmountFormat: 2) : 'N/A'}}
                                  </span>
                                  <span class="total_value" *ngIf="companynumbersShow2">
                                    {{companynumbersData?.MarketCap? '$'+(companynumbersData?.MarketCap |AmountFormat: 2) : 'N/A'}}
                                  </span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/E/enterprise-value">
                                    Enterprise Value
                                  </a>
                                  <span class="total_value">{{itemnumber?.EnterpriseValue?
                                    '$'+(itemnumber?.EnterpriseValue | AmountFormat: 2) : 'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/T/tangible-book-value-tbv">
                                    Tangible Book Value
                                  </a>
                                  <span class="total_value" *ngIf="companynumbersShow">{{itemnumber?.TangibleBookValue?
                                    '$'+(itemnumber?.TangibleBookValue | AmountFormat: 2) : 'N/A'}}
                                  </span>
                                  <span class="total_value" *ngIf="companynumbersShow2">
                                    {{companynumbersData?.TangibleBookValue? '$'+(companynumbersData?.TangibleBookValue | AmountFormat: 2) : 'N/A'}}
                                  </span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/P/price-to-earnings-ratio-pe-ratio">
                                    P/E Ratio (TM)
                                  </a>
                                  <span class="total_value" *ngIf="companynumbersShow">{{itemnumber?.PERatio? (itemnumber.PERatio.toFixed(2)) :'N/A'}}</span>
                                  <span class="total_value" *ngIf="companynumbersShow2">{{companynumbersData?.PERatio? (companynumbersData.PERatio.toFixed(2)) :'N/A'}}</span>
                                  
                                </li>
                                <li>
                                  <a class="value_name" href="https://manicmarket.com/investor-dictionary/F/forward-pe">
                                    Forward P/E
                                  </a>
                                  <span class="total_value" *ngIf="companynumbersShow">
                                    {{itemnumber?.ForwardPE? (itemnumber.ForwardPE | number : '.2-2') :'N/A'}}
                                  </span>
                                  <span class="total_value" *ngIf="companynumbersShow2">
                                    {{companynumbersData?.ForwardPE? (companynumbersData.ForwardPE | number : '.2-2') :'N/A'}}
                                  </span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/P/price-to-sales">
                                    Price to Sales
                                  </a>
                                  <span class="total_value"
                                    *ngIf="itemnumber?.PriceToSales > 100">{{itemnumber?.PriceToSales?
                                    ''+(itemnumber.PriceToSales | AmountFormat: 2):'N/A'}}</span>
                                  <span class="total_value"
                                    *ngIf="itemnumber?.PriceToSales < 100">{{itemnumber?.PriceToSales?
                                    ''+(itemnumber.PriceToSales | number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/P/price-to-book-ratio-pb-ratio">
                                    Price to Book
                                  </a>
                                  <span class="total_value" *ngIf="itemnumber?.PriceToBook > 100 && companynumbersShow">{{itemnumber?.PriceToBook?
                                    ''+(itemnumber.PriceToBook | AmountFormat: 2):'N/A'}}</span>

                                  <span class="total_value" *ngIf="itemnumber?.PriceToBook < 100 && companynumbersShow">{{itemnumber?.PriceToBook?
                                    ''+(itemnumber.PriceToBook | number : '.2-2'):'N/A'}}</span>

                                    <span class="total_value" *ngIf="companynumbersData?.PriceToBook < 100 && companynumbersShow2" >{{companynumbersData?.PriceToBook?
                                    ''+(companynumbersData?.PriceToBook | number : '.2-2'):'N/A'}}</span> 
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/P/price-to-cash-flow">
                                    Price to Cash Flow
                                  </a>
                                  <span class="total_value"
                                    *ngIf="itemnumber?.PriceToCashFlow > 100">{{itemnumber?.PriceToCashFlow?
                                    ''+(itemnumber.PriceToCashFlow | AmountFormat: 2):'N/A'}}</span>
                                  <span class="total_value"
                                    *ngIf="itemnumber?.PriceToCashFlow < 100">{{itemnumber?.PriceToCashFlow?
                                    ''+(itemnumber.PriceToCashFlow | number : '.2-2'):'N/A'}}</span>
                                </li>
                              </ul>
                            </div>
                            <div class="listdata">
                              <h5>Profitability</h5>
                              <ul>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/E/earnings-per-share-eps">
                                    Earnings Per Share (TTM)
                                  </a>
                                  <span class="total_value" *ngIf="companynumbersShow">
                                    ${{itemnumber?.EarningsPerShareTTM? (itemnumber.EarningsPerShareTTM | number : '.2-2'):'N/A'}}</span>
                                  <span class="total_value" *ngIf="companynumbersShow2">
                                    ${{companynumbersData?.EarningsPerShareTTM? (companynumbersData.EarningsPerShareTTM | number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/E/earnings-per-share-forward">
                                    Earnings Per Share (Forward)
                                  </a>
                                  <span class="total_value" *ngIf="companynumbersShow">${{itemnumber?.EarningsPerShareForward?
                                    (itemnumber.EarningsPerShareForward | number : '.2-2'):'N/A'}}</span>

                                  <span class="total_value" *ngIf="companynumbersShow2">${{companynumbersData?.EarningsPerShareForward?
                                    (companynumbersData.EarningsPerShareForward | number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/G/gross-margin">
                                    Gross Margin
                                  </a>
                                  <span class="total_value">
                                    {{itemnumber?.GrossMargin? ((itemnumber?.GrossMargin * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/O/operating-margin">
                                    Operating Margin
                                  </a>
                                  <span class="total_value">
                                    {{itemnumber?.OperatingMargin? ((itemnumber?.OperatingMargin * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name" href="https://manicmarket.com/investor-dictionary/N/net-margin">
                                    Net Margin
                                  </a>
                                  <span class="total_value">
                                    {{itemnumber?.NetMargin? ((itemnumber?.NetMargin * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/R/return-on-assets-roa">
                                    Return on Assets
                                  </a>
                                  <span class="total_value">
                                    {{itemnumber?.ReturnonAssets? ((itemnumber?.ReturnonAssets * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/R/return-on-equity-roe">
                                    Return on Equity
                                  </a>
                                  <span class="total_value">
                                    {{itemnumber?.ReturnonEquity? ((itemnumber?.ReturnonEquity * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name" href="https://manicmarket.com/investor-dictionary/R/return-on-capital-roc
                                  ">
                                    Return on Capital
                                  </a>
                                  <span class="total_value">
                                    {{itemnumber?.ReturnonCapital? ((itemnumber?.ReturnonCapital * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/R/revenue-per-employee-rpe">
                                    Revenue Per Employee
                                  </a>
                                  <span class="total_value">${{itemnumber?.RevenuePerEmployee?
                                    (itemnumber?.RevenuePerEmployee | number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/I/income-per-employee">
                                    Income Per Employee
                                  </a>
                                  <span class="total_value">${{itemnumber?.IncomePerEmployee?
                                    (itemnumber.IncomePerEmployee | number : '.2-2'):'N/A'}}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="listdata">
                              <h5>Miscellaneous</h5>
                              <ul>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/D/dividend-yield">
                                    Dividend Yield
                                  </a>
                                  <span class="total_value" *ngIf="companynumbersShow">{{itemnumber?.DividendYield ? ((itemnumber.DividendYield * 100)| number : '1.2-2' ) +'%':'N/A'}}</span>

                                  <span class="total_value" *ngIf="companynumbersShow2">{{companynumbersData?.DividendYield ?
                                    ((companynumbersData.DividendYield * 100)| number : '1.2-2' ) +'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/D/dividend-amount">
                                    Dividend Amount
                                  </a>
                                  <span class="total_value">{{itemnumber?.DividendAmount? (itemnumber.DividendAmount |
                                    number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/E/ex-dividend-date">
                                    Ex-Dividend Date
                                  </a>
                                  <span class="total_value">{{itemnumber?.ExDividendDate ?
                                    ( itemnumber?.ExDividendDate | date: 'MM/dd/yyyy' ):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/E/earnings-date">
                                    Earnings Date
                                  </a>
                                  <span class="total_value">{{itemnumber?.EarningsDate ?
                                    ( itemnumber?.EarningsDate | date: 'MM/dd/yyyy' ):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name" href="https://manicmarket.com/investor-dictionary/V/volume">
                                    Volume
                                  </a>
                                  <span class="total_value">{{itemnumber?.Volume? (itemnumber.Volume | number: '')
                                    :'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/A/average-volume">
                                    Avg Volume
                                  </a>
                                  <span class="total_value" *ngIf="companynumbersShow">{{itemnumber?.AvgVolume? (itemnumber.AvgVolume | number: ''):'N/A'}}</span>
                                  <span class="total_value" *ngIf="companynumbersShow2">{{companynumbersData?.AvgVolume? (companynumbersData.AvgVolume | number: ''):'N/A'}}</span>

                                </li>
                                <li>
                                  <a class="value_name" href="https://manicmarket.com/investor-dictionary/B/beta">
                                    Beta
                                  </a>
                                  <span class="total_value">{{itemnumber?.Beta? (itemnumber.Beta | number :
                                    '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/1/52-week-range">
                                    52 Week Range
                                  </a>
                                  <span class="total_value" *ngIf="companynumbersShow">
                                    ${{itemnumber?.Week52Low? (itemnumber.Week52Low | number : '.2-2'):'N/A'}}
                                    - ${{itemnumber?.Week52High? (itemnumber.Week52High | number :'.2-2'):'N/A'}}
                                  </span>
                                  <span class="total_value" *ngIf="companynumbersShow2">
                                    ${{companynumbersData?.Week52Low? (companynumbersData.Week52Low | number : '.2-2'):'N/A'}}
                                    -${{companynumbersData?.Week52High? (companynumbersData.Week52High | number :'.2-2'):'N/A'}}
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div class="listdata">
                              <h5>Liquidity</h5>
                              <ul>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/C/current-ratio">
                                    Current Ratio
                                  </a>
                                  <span class="total_value">{{itemnumber?.CurrentRatio? (itemnumber.CurrentRatio | number : '.2-2') :'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/Q/quick-ratio">
                                    Quick Ratio
                                  </a>
                                  <span class="total_value">{{itemnumber?.QuickRatio? (itemnumber.QuickRatio | number :
                                    '.2-2') :'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/C/current-ratio">
                                    Cash Ratio
                                  </a>
                                  <span class="total_value">{{itemnumber?.CashRatio? (itemnumber.CashRatio | number :
                                    '.2-2') :'N/A'}}</span>
                                </li>
                              </ul>
                            </div>
                            <div class="listdata">
                              <h5>Ownership</h5>
                              <ul>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/S/shares-outstanding">
                                    Shares Outstanding
                                  </a>
                                  <span class="total_value" *ngIf="companynumbersShow">
                                    {{itemnumber?.SharesOutstanding? (itemnumber.SharesOutstanding | AmountFormat: 2):'N/A'}}
                                  </span>
                                  <span class="total_value" *ngIf="companynumbersShow2">
                                    {{companynumbersData?.SharesOutstanding? (companynumbersData.SharesOutstanding | AmountFormat: 2):'N/A'}}
                                  </span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/S/shares-short">
                                    Share Short
                                  </a>
                                  <span class="total_value">{{itemnumber?.SharesShort? (itemnumber.SharesShort |
                                    AmountFormat: 2) :'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/S/short-percentage">
                                    Short Percentage
                                  </a>
                                  <span>{{(itemnumber.ShortPercentage || itemnumber.ShortPercentage == 0) ?
                                    ( itemnumber.ShortPercentage>0 ? '' + ((itemnumber.ShortPercentage * 100)|
                                    number : '1.2-2' ) : ( itemnumber.ShortPercentage) )+'%':'N/A' }}
                                  </span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/I/insider-owned">
                                    Insider Owned
                                  </a>
                                  <span class="total_value">{{itemnumber?.InsiderOwned? (itemnumber.InsiderOwned |
                                    number : '.2-2') :'N/A'}}%</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/I/institution-owned">
                                    Institution Owned
                                  </a>
                                  <span class="total_value">{{itemnumber?.InsiderOwned? (itemnumber.InsiderOwned |
                                    number : '.2-2') :'N/A'}}%</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="data_numbercell newnumber_cell" *ngIf="this.numsymbol != null">
                        <div class="row news_btn_se">
                          <div class="col-md-12 news_btn_in">
                            <button type="button" (click)="clearbalance()">{{this.numsymbol}} X</button>
                          </div>
                          <div class="col-md-6">
                            <div class="listdata">
                              <h5>Valuation</h5>
                              <ul>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/M/market-capitilization-market-cap">
                                    Market Cap
                                  </a>
                                  <span class="total_value" *ngIf="companynumbersShow">{{numberGet[i]?.MarketCap? '$'+(numberGet[i]?.MarketCap |
                                    AmountFormat: 2) : 'N/A'}}</span>
                                  <span class="total_value" *ngIf="companynumbersShow2">
                                    {{companynumbersData?.MarketCap? '$'+(companynumbersData?.MarketCap |AmountFormat: 2) : 'N/A'}}
                                  </span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/E/enterprise-value">
                                    Enterprise Value
                                  </a>
                                  <span class="total_value">{{numberGet[i]?.EnterpriseValue?
                                    '$'+(numberGet[i]?.EnterpriseValue | AmountFormat: 2) : 'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/T/tangible-book-value-tbv">
                                    Tangible Book Value
                                  </a>
                                  <span class="total_value">{{numberGet[i]?.TangibleBookValue?
                                    '$'+(numberGet[i]?.TangibleBookValue | AmountFormat: 2) : 'N/A'}}
                                  </span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/P/price-to-earnings-ratio-pe-ratio">
                                    P/E Ratio (TM)
                                  </a>
                                  <span class="total_value">{{numberGet[i]?.PERatio? numberGet[i].PERatio
                                    :'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name" href="https://manicmarket.com/investor-dictionary/F/forward-pe">
                                    Forward P/E
                                  </a>
                                  <span class="total_value">
                                    {{numberGet[i]?.ForwardPE? (numberGet[i].ForwardPE | number : '.2-2') :'N/A'}}
                                  </span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/P/price-to-sales">
                                    Price to Sales
                                  </a>
                                  <span class="total_value"
                                    *ngIf="numberGet[i]?.PriceToSales > 100">{{numberGet[i]?.PriceToSales?
                                    ''+(numberGet[i].PriceToSales | AmountFormat: 2):'N/A'}}</span>
                                  <span class="total_value"
                                    *ngIf="numberGet[i]?.PriceToSales < 100">{{numberGet[i]?.PriceToSales?
                                    ''+(numberGet[i].PriceToSales | number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/P/price-to-book-ratio-pb-ratio">
                                    Price to Book
                                  </a>
                                  <span class="total_value"
                                    *ngIf="numberGet[i]?.PriceToBook > 100">{{numberGet[i]?.PriceToBook?
                                    ''+(numberGet[i].PriceToBook | AmountFormat: 2):'N/A'}}</span>
                                  <span class="total_value"
                                    *ngIf="numberGet[i]?.PriceToBook < 100">{{numberGet[i]?.PriceToBook?
                                    ''+(numberGet[i].PriceToBook | number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/P/price-to-cash-flow">
                                    Price to Cash Flow
                                  </a>
                                  <span class="total_value"
                                    *ngIf="numberGet[i]?.PriceToCashFlow > 100">{{numberGet[i]?.PriceToCashFlow?
                                    ''+(numberGet[i].PriceToCashFlow | AmountFormat: 2):'N/A'}}</span>
                                  <span class="total_value"
                                    *ngIf="numberGet[i]?.PriceToCashFlow < 100">{{numberGet[i]?.PriceToCashFlow?
                                    ''+(numberGet[i].PriceToCashFlow | number : '.2-2'):'N/A'}}</span>
                                </li>
                              </ul>
                            </div>
                            <div class="listdata">
                              <h5>Profitability</h5>
                              <ul>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/E/earnings-per-share-eps">
                                    Earnings Per Share (TTM)
                                  </a>
                                  <span class="total_value">${{numberGet[i]?.EarningsPerShareTTM?
                                    (numberGet[i].EarningsPerShareTTM | number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Earnings Per Share (Forward) </span>
                                  <span class="total_value">${{numberGet[i]?.EarningsPerShareForward?
                                    (numberGet[i].EarningsPerShareForward | number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/G/gross-margin">
                                    Gross Margin
                                  </a>
                                  <span class="total_value">
                                    {{numberGet[i]?.GrossMargin? ((numberGet[i]?.GrossMargin * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Operating Margin </span>
                                  <span class="total_value">
                                    {{numberGet[i]?.OperatingMargin? ((numberGet[i]?.OperatingMargin * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Net Margin</span>
                                  <span class="total_value">
                                    {{numberGet[i]?.NetMargin? ((numberGet[i]?.NetMargin * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/R/return-on-assets-roa">
                                    Return on Assets
                                  </a>
                                  <span class="total_value">
                                    {{numberGet[i]?.ReturnonAssets? ((numberGet[i]?.ReturnonAssets * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/R/return-on-equity-roe">
                                    Return on Equity
                                  </a>
                                  <span class="total_value">
                                    {{numberGet[i]?.ReturnonEquity? ((numberGet[i]?.ReturnonEquity * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Return on Capital</span>
                                  <span class="total_value">
                                    {{numberGet[i]?.ReturnonCapital? ((numberGet[i]?.ReturnonCapital * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Revenue Per Employee</span>
                                  <span class="total_value">{{numberGet[i]?.RevenuePerEmployee?
                                    (numberGet[i]?.RevenuePerEmployee | number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Income Per Employee</span>
                                  <span class="total_value">{{numberGet[i]?.IncomePerEmployee?
                                    (numberGet[i].IncomePerEmployee | number : '.2-2'):'N/A'}}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="listdata">
                              <h5>Miscellaneous</h5>
                              <ul>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/D/dividend-yield">
                                    Dividend Yield
                                  </a>
                                  <span class="total_value">{{ (numberGet[i]?.DividendYield |number) ?
                                    (numberGet[i]?.DividendYield | number : '1.2-2' ) +'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Dividend Amount</span>
                                  <span class="total_value">{{numberGet[i]?.DividendAmount? (numberGet[i].DividendAmount
                                    |
                                    number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/E/ex-dividend-date">
                                    Ex-Dividend Date
                                  </a>
                                  <span class="total_value">{{numberGet[i]?.ExDividendDate ?
                                    ( numberGet[i]?.ExDividendDate | date: 'MM/dd/yyyy' ):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/E/earnings-date">
                                    Earnings Date
                                  </a>
                                  <span class="total_value">{{numberGet[i]?.EarningsDate ?
                                    ( numberGet[i]?.EarningsDate | date: 'MM/dd/yyyy' ):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name" href="https://manicmarket.com/investor-dictionary/V/volume">
                                    Volume
                                  </a>
                                  <span class="total_value">{{numberGet[i]?.Volume? numberGet[i].Volume :'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Avg Volume</span>
                                  <span class="total_value">{{numberGet[i]?.AvgVolume? numberGet[i].AvgVolume
                                    :'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name" href="https://manicmarket.com/investor-dictionary/B/beta">
                                    Beta
                                  </a>
                                  <span class="total_value">{{numberGet[i]?.Beta? (numberGet[i].Beta | number :
                                    '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">52 Week Range</span>
                                  <span class="total_value">${{numberGet[i]?.Week52High? (numberGet[i].Week52High |
                                    number :
                                    '.2-2'):'N/A'}}-${{numberGet[i]?.Week52Low? (numberGet[i].Week52Low | number :
                                    '.2-2'):'N/A'}}</span>
                                </li>
                              </ul>
                            </div>
                            <div class="listdata">
                              <h5>Liquidity</h5>
                              <ul>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/C/current-ratio">
                                    Current Ratio
                                  </a>
                                  <span class="total_value">{{numberGet[i]?.CurrentRatio? (numberGet[i].CurrentRatio |
                                    number : '.2-2') :'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/Q/quick-ratio">
                                    Quick Ratio
                                  </a>
                                  <span class="total_value">{{numberGet[i]?.QuickRatio? (numberGet[i].QuickRatio |
                                    number :
                                    '.2-2') :'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Cash Ratio</span>
                                  <span class="total_value">{{numberGet[i]?.CashRatio? (numberGet[i].CashRatio | number
                                    :
                                    '.2-2') :'N/A'}}</span>
                                </li>
                              </ul>
                            </div>
                            <div class="listdata">
                              <h5>Ownership</h5>
                              <ul>
                                <li>
                                  <span class="value_name">Shares Outstanding</span>
                                  <span class="total_value">{{numberGet[i]?.SharesOutstanding?
                                    (numberGet[i].SharesOutstanding | AmountFormat: 2) :'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Share Short</span>
                                  <span class="total_value">{{numberGet[i]?.SharesShort? (numberGet[i].SharesShort |
                                    AmountFormat: 2) :'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Short Percentage </span>
                                  <span class="total_value">
                                    {{(numberGet[i]?.ShortPercentage || numberGet[i]?.ShortPercentage == 0) ?
                                    ( numberGet[i]?.ShortPercentage>0 ? '' + ((numberGet[i]?.ShortPercentage * 100)|
                                    number : '1.2-2' ) : ( numberGet[i]?.ShortPercentage) )+'%':'N/A' }}</span>
                                </li>
                                <li>
                                  <span class="value_name">Insider Owned</span>
                                  <span class="total_value">{{numberGet[i]?.InsiderOwned? (numberGet[i].InsiderOwned |
                                    number : '.2-2') :'N/A'}}%</span>
                                </li>
                                <li>
                                  <span class="value_name">Institution Owned </span>
                                  <span class="total_value">{{numberGet[i]?.InsiderOwned? (numberGet[i].InsiderOwned |
                                    number : '.2-2') :'N/A'}}%</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="data_numbercell newnumber_cell" *ngIf="isSuperUser1">
                        <div class="row news_btn_se">
                          <div class="col-md-12 news_btn_in">
                            <button type="button" (click)="clearbalance()">{{this.numsymbol2}} X</button>
                          </div>
                          <div class="col-md-6">
                            <div class="listdata">
                              <h5>Valuation</h5>
                              <ul>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/M/market-capitilization-market-cap">
                                    Market Cap
                                  </a>
                                  <span class="total_value">{{numberGet2[i]?.MarketCap? '$'+(numberGet2[i]?.MarketCap |
                                    AmountFormat: 2) : 'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/E/enterprise-value">
                                    Enterprise Value
                                  </a>
                                  <span class="total_value">{{numberGet2[i]?.EnterpriseValue?
                                    '$'+(numberGet2[i]?.EnterpriseValue | AmountFormat: 2) : 'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/T/tangible-book-value-tbv">
                                    Tangible Book Value
                                  </a>
                                  <span class="total_value">{{numberGet2[i]?.TangibleBookValue?
                                    '$'+(numberGet2[i]?.TangibleBookValue | AmountFormat: 2) : 'N/A'}}
                                  </span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/P/price-to-earnings-ratio-pe-ratio">
                                    P/E Ratio (TM)
                                  </a>
                                  <span class="total_value">{{numberGet2[i]?.PERatio? numberGet2[i].PERatio
                                    :'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name" href="https://manicmarket.com/investor-dictionary/F/forward-pe">
                                    Forward P/E
                                  </a>
                                  <span class="total_value">
                                    {{numberGet2[i]?.ForwardPE? (numberGet2[i].ForwardPE | number : '.2-2') :'N/A'}}
                                  </span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/P/price-to-sales">
                                    Price to Sales
                                  </a>
                                  <span class="total_value"
                                    *ngIf="numberGet2[i]?.PriceToSales > 100">{{numberGet2[i]?.PriceToSales?
                                    ''+(numberGet2[i].PriceToSales | AmountFormat: 2):'N/A'}}</span>
                                  <span class="total_value"
                                    *ngIf="numberGet2[i]?.PriceToSales < 100">{{numberGet2[i]?.PriceToSales?
                                    ''+(numberGet2[i].PriceToSales | number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/P/price-to-book-ratio-pb-ratio">
                                    Price to Book
                                  </a>
                                  <span class="total_value"
                                    *ngIf="numberGet2[i]?.PriceToBook > 100">{{numberGet2[i]?.PriceToBook?
                                    ''+(numberGet2[i].PriceToBook | AmountFormat: 2):'N/A'}}</span>
                                  <span class="total_value"
                                    *ngIf="numberGet2[i]?.PriceToBook < 100">{{numberGet2[i]?.PriceToBook?
                                    ''+(numberGet2[i].PriceToBook | number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/P/price-to-cash-flow">
                                    Price to Cash Flow
                                  </a>
                                  <span class="total_value"
                                    *ngIf="numberGet2[i]?.PriceToCashFlow > 100">{{numberGet2[i]?.PriceToCashFlow?
                                    ''+(numberGet2[i].PriceToCashFlow |
                                    AmountFormat: 2):'N/A'}}</span>
                                  <span class="total_value"
                                    *ngIf="numberGet2[i]?.PriceToCashFlow < 100">{{numberGet2[i]?.PriceToCashFlow?
                                    ''+(numberGet2[i].PriceToCashFlow |
                                    number : '.2-2'):'N/A'}}</span>
                                </li>
                              </ul>
                            </div>
                            <div class="listdata">
                              <h5>Profitability</h5>
                              <ul>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/E/earnings-per-share-eps">
                                    Earnings Per Share (TTM)
                                  </a>
                                  <span class="total_value">${{numberGet2[i]?.EarningsPerShareTTM?
                                    (numberGet2[i].EarningsPerShareTTM | number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Earnings Per Share (Forward) </span>
                                  <span class="total_value">${{numberGet2[i]?.EarningsPerShareForward?
                                    (numberGet2[i].EarningsPerShareForward | number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/G/gross-margin">
                                    Gross Margin
                                  </a>
                                  <span class="total_value">
                                    {{numberGet2[i]?.GrossMargin? ((numberGet2[i]?.GrossMargin * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Operating Margin </span>
                                  <span class="total_value">
                                    {{numberGet2[i]?.OperatingMargin? ((numberGet2[i]?.OperatingMargin * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Net Margin</span>
                                  <span class="total_value">
                                    {{numberGet2[i]?.NetMargin? ((numberGet2[i]?.NetMargin * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/R/return-on-assets-roa">
                                    Return on Assets
                                  </a>
                                  <span class="total_value">
                                    {{numberGet2[i]?.ReturnonAssets? ((numberGet2[i]?.ReturnonAssets * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/R/return-on-equity-roe">
                                    Return on Equity
                                  </a>
                                  <span class="total_value">
                                    {{numberGet2[i]?.ReturnonEquity? ((numberGet2[i]?.ReturnonEquity * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Return on Capital</span>
                                  <span class="total_value">
                                    {{numberGet2[i]?.ReturnonCapital? ((numberGet2[i]?.ReturnonCapital * 100) | number :
                                    '1.2-2')+'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Revenue Per Employee</span>
                                  <span class="total_value">{{numberGet2[i]?.RevenuePerEmployee?
                                    (numberGet2[i]?.RevenuePerEmployee | number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Income Per Employee</span>
                                  <span class="total_value">{{numberGet2[i]?.IncomePerEmployee?
                                    (numberGet2[i].IncomePerEmployee | number : '.2-2'):'N/A'}}</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="listdata">
                              <h5>Miscellaneous</h5>
                              <ul>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/D/dividend-yield">
                                    Dividend Yield
                                  </a>
                                  <span class="total_value">{{ (numberGet2[i]?.DividendYield |number) ?
                                    (numberGet2[i]?.DividendYield | number : '1.2-2' ) +'%':'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Dividend Amount</span>
                                  <span class="total_value">{{numberGet2[i]?.DividendAmount?
                                    (numberGet2[i].DividendAmount |
                                    number : '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/E/ex-dividend-date">
                                    Ex-Dividend Date
                                  </a>
                                  <span class="total_value">{{numberGet2[i]?.ExDividendDate ?
                                    ( numberGet2[i]?.ExDividendDate | date: 'MM/dd/yyyy' ):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/E/earnings-date">
                                    Earnings Date
                                  </a>
                                  <span class="total_value">{{numberGet2[i]?.EarningsDate ?
                                    ( numberGet2[i]?.EarningsDate | date: 'MM/dd/yyyy' ):'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name" href="https://manicmarket.com/investor-dictionary/V/volume">
                                    Volume
                                  </a>
                                  <span class="total_value">{{numberGet2[i]?.Volume? numberGet2[i].Volume
                                    :'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Avg Volume</span>
                                  <span class="total_value">{{numberGet2[i]?.AvgVolume? numberGet2[i].AvgVolume
                                    :'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name" href="https://manicmarket.com/investor-dictionary/B/beta">
                                    Beta
                                  </a>
                                  <span class="total_value">{{numberGet2[i]?.Beta? (numberGet2[i].Beta | number :
                                    '.2-2'):'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">52 Week Range</span>
                                  <span class="total_value">${{numberGet2[i]?.Week52High? (numberGet2[i].Week52High |
                                    number :
                                    '.2-2'):'N/A'}}-${{numberGet2[i]?.Week52Low? (numberGet2[i].Week52Low | number :
                                    '.2-2'):'N/A'}}</span>
                                </li>
                              </ul>
                            </div>
                            <div class="listdata">
                              <h5>Liquidity</h5>
                              <ul>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/C/current-ratio">
                                    Current Ratio
                                  </a>
                                  <span class="total_value">{{numberGet2[i]?.CurrentRatio? (numberGet2[i].CurrentRatio |
                                    number : '.2-2') :'N/A'}}</span>
                                </li>
                                <li>
                                  <a class="value_name"
                                    href="https://manicmarket.com/investor-dictionary/Q/quick-ratio">
                                    Quick Ratio
                                  </a>
                                  <span class="total_value">{{numberGet2[i]?.QuickRatio? (numberGet2[i].QuickRatio |
                                    number :
                                    '.2-2') :'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Cash Ratio</span>
                                  <span class="total_value">{{numberGet2[i]?.CashRatio? (numberGet2[i].CashRatio |
                                    number : '.2-2') :'N/A'}}</span>
                                </li>
                              </ul>
                            </div>
                            <div class="listdata">
                              <h5>Ownership</h5>
                              <ul>
                                <li>
                                  <span class="value_name">Shares Outstanding</span>
                                  <span class="total_value">{{numberGet2[i]?.SharesOutstanding?
                                    (numberGet2[i].SharesOutstanding | AmountFormat: 2):'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Share Short</span>
                                  <span class="total_value">{{numberGet2[i]?.SharesShort? (numberGet2[i].SharesShort |
                                    AmountFormat: 2) :'N/A'}}</span>
                                </li>
                                <li>
                                  <span class="value_name">Short Percentage </span>
                                  <span class="total_value">
                                    {{(numberGet2[i]?.ShortPercentage || numberGet2[i]?.ShortPercentage == 0) ?
                                    ( numberGet2[i]?.ShortPercentage>0 ? '' + ((numberGet2[i]?.ShortPercentage * 100)|
                                    number : '1.2-2' ) : ( numberGet[i]?.ShortPercentage) )+'%':'N/A' }}
                                  </span>
                                </li>
                                <li>
                                  <span class="value_name">Insider Owned</span>
                                  <span class="total_value">{{numberGet2[i]?.InsiderOwned? (numberGet2[i].InsiderOwned |
                                    number : '.2-2') :'N/A'}}%</span>
                                </li>
                                <li>
                                  <span class="value_name">Institution Owned </span>
                                  <span class="total_value">{{numberGet2[i]?.InsiderOwned? (numberGet2[i].InsiderOwned |
                                    number : '.2-2') :'N/A'}}%</span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <div class="tab-pane fade" id="financials" role="tabpanel" aria-labelledby="financials-tab"
                  *ngIf="this.parentTabValue == 3">
                  <div class="row news_subhead mt-2 mb-1 financial_search">
                    <div class="col-md-5">
                      <div class="form-group news_seraching newicon" id="search-bar">
                        <button type="button" class="sr_btn"><i class="fa fa-search"></i></button>
                        <form (ngSubmit)="getSearchData()" *ngIf="isShowSearchBar">
                          <div class="form-group">
                            <ng-select #ngSelectSearch class="form-control search-bar" bindLabel="name"
                              placeholder="Compare Symbol" [searchable]="true" [ngModelOptions]="{standalone: true}"
                              (search)="searchValue=$event?.term;getSearchData()" [clearable]="true"
                              [(ngModel)]="searchValue" (focus)="showHideOptions();" (blur)="isOpenSearchBar=false;"
                              (clear)="onClear()" clearAllText="" (keyup.enter)="comparebalance(searchValue)"
                              (click)="comparebalance(searchValue)">
                              <ng-container *ngIf="searchValue">
                                <ng-option [value]="item.value" *ngFor="let item of select2Model">
                                  <img
                                    [src]="item.changePercent > 0 ? '../../assets/img/market_up.png' : item.changePercent < 0 ? '../../assets/img/market_down.png' : '../../assets/img/no_dataupdate.png' " alt="">
                                  <a class="a-no-decore" (click)="comparebalance(item?.value)"
                                    (keyup.enter)="comparebalance(item?.value)">
                                    <strong>{{item?.value.symbol}}</strong><small>{{item.label}}</small>
                                  </a>
                                </ng-option>
                              </ng-container>
                            </ng-select>
                          </div>
                        </form>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <ul class="nav nav-tabs w33" id="myTab2" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active text-capitalize" (click)="tabValue('income-tab')" id="income-tab"
                            data-toggle="tab" href="#income" role="tab" aria-controls="income"
                            aria-selected="false">income <span> Statement</span></a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link text-capitalize" id="balance-tab" data-toggle="tab" href="#balance"
                            (click)="tabValue('balance-tab')" role="tab" aria-controls="balance"
                            aria-selected="true">Balance <span>Sheet</span></a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link text-capitalize" (click)="tabValue('cashflow-tab')" id="cashflow-tab"
                            data-toggle="tab" href="#cashflow" role="tab" aria-controls="cashflow"
                            aria-selected="false">Cash Flow</a>
                        </li>

                      </ul>
                    </div>
                  </div>
                  <div class="tab-content fainens_contant" id="myTabContent">
                    <!-- BALANCE START -->
                    <div class="tab-pane fade  three_staptb" id="balance" role="tabpanel" aria-labelledby="home-tab"
                      *ngIf="this.tab_Value == 'balance-tab'">
                      <!-- BALANCE TABLE START -->
                      <table id="balanceSheet1" class="table fainens_table new_sheet">
                        <thead class="year_headedata">
                          <tr>
                            <th width="20%"></th>
                            <th *ngFor="let balance of getdata;let i=index" width="20%">
                              <span class="widthbox">{{balance?.date | date:'yyyy'}}</span>
                              <span class="widthbox" *ngIf="this.symbol1 != null" (click)="clearbalance()">
                                <span class="bluebg">{{this.symbol1}}<i class="fa fa-close"></i></span>
                              </span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}" (click)="clearbala2()">
                                <span class="bluebg">{{this.symbol2}}<i class="fa fa-close"></i></span>
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th style="width: 20%;">
                              <i class="fa fa-chevron-down icon_open" *ngIf="!isIcon" (click)="totalAssets()"></i>
                              <i class="fa fa-chevron-up icon_open" *ngIf="isIcon" (click)="totalAssets()"></i>
                              <a href="https://manicmarket.com/investor-dictionary/T/total-assets">
                                <h1>Total Assets</h1>
                              </a>
                            </th>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.totalAssets?(balance?.totalAssets|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox">
                                {{balanceGet[i]?.totalAssets?(balanceGet[i]?.totalAssets|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.totalAssets?(balance2[i]?.totalAssets|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="total_assets" *ngIf="isAssets">
                            <th style="width: 20%;">
                              <i class="fa fa-chevron-down icon_open" *ngIf="!isIcon1" (click)="currentAssets()"></i>
                              <i class="fa fa-chevron-up icon_open" *ngIf="isIcon1" (click)="currentAssets()"></i>

                              <a href="https://manicmarket.com/investor-dictionary/C/current-assets">
                                <h2>Current Assets</h2>
                              </a>
                            </th>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.totalCurrentAssets?(balance?.totalCurrentAssets|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.totalCurrentAssets?(balanceGet[i]?.totalCurrentAssets|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.totalCurrentAssets?(balance2[i]?.totalCurrentAssets|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="current_assets" *ngIf="isCurrent">
                            <td class="bor_bgnone" style="width: 20%;padding-left: 40px;">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/C/cash">Cash</a>
                            </td>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox"> {{balance?.cash?(balance?.cash|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.cash?(balanceGet[i]?.cash|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.cash?(balance2[i]?.cash|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="current_assets" *ngIf="isCurrent">
                            <td class="bor_bgnone" style="width: 20%;padding-left: 40px;">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/S/short-term-investments">Short Term
                                Investments</a>
                            </td>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.shortTermInvestments?(balance?.shortTermInvestments|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.shortTermInvestments?(balanceGet[i]?.shortTermInvestments|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.shortTermInvestments?(balance2[i]?.shortTermInvestments|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="current_assets" *ngIf="isCurrent">
                            <td class="bor_bgnone" style="width: 20%;padding-left: 40px;">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/R/receivables">Receivables</a>
                            </td>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.netReceivables?(balance?.netReceivables|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.netReceivables?(balanceGet[i]?.netReceivables|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.netReceivables?(balance2[i]?.netReceivables|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="current_assets" *ngIf="isCurrent">
                            <td class="bor_bgnone" style="width: 20%;padding-left: 40px;">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/I/inventory">Inventory</a>
                            </td>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.inventory?(balance?.inventory|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.inventory?(balanceGet[i]?.inventory|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.inventory?(balance2[i]?.inventory|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="current_assets" *ngIf="isCurrent">
                            <td class="bor_bgnone" style="width: 20%;padding-left: 40px;">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/O/other-current-assets">Other Current
                                Assets</a>
                            </td>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.otherCurrentAssets?(balance?.otherCurrentAssets|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.otherCurrentAssets?(balanceGet[i]?.otherCurrentAssets|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.otherCurrentAssets?(balance2[i]?.otherCurrentAssets|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="total_assets" *ngIf="isAssets">
                            <th style="width: 20%;padding-left: 20px;">
                              <i class="fa fa-chevron-down icon_open" *ngIf="!isIcon2" (click)="noncurrentAssets()"></i>
                              <i class="fa fa-chevron-up icon_open" *ngIf="isIcon2" (click)="noncurrentAssets()"></i>

                              <a href="https://manicmarket.com/investor-dictionary/N/non-current-assets">
                                <h2>Non-Current Assets</h2>
                              </a>
                            </th>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.NonCurrentAssets?(balance?.NonCurrentAssets|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.NonCurrentAssets?(balanceGet[i]?.NonCurrentAssets|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.NonCurrentAssets?(balance2[i]?.NonCurrentAssets|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="non_assets" *ngIf="isnoncurrent">
                            <td class="bor_bgnone" style="width: 20%;padding-left: 40px;">
                              <a style="color: inherit;" class="short_nm"
                                href="https://manicmarket.com/investor-dictionary/P/property-plant-and-equipment">P<span>roperty</span>
                                P<span>lant</span> & E<span>quipment</span></a>
                            </td>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.propertyPlantEquipment?(balance?.propertyPlantEquipment|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.propertyPlantEquipment?(balanceGet[i]?.propertyPlantEquipment|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.propertyPlantEquipment?(balance2[i]?.propertyPlantEquipment|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="non_assets" *ngIf="isnoncurrent">
                            <td class="bor_bgnone" style="width: 20%;padding-left: 40px;">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/G/goodwill">Goodwill</a>
                            </td>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.goodWill?(balance?.goodWill|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.goodWill?(balanceGet[i]?.goodWill|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.goodWill?(balance2[i]?.goodWill|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="non_assets" *ngIf="isnoncurrent">
                            <td class="bor_bgnone" style="width: 20%;padding-left: 40px;">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/I/intangible-assets">Intangible
                                Assets</a>
                            </td>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.intangibleAssets?(balance?.intangibleAssets|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.intangibleAssets?(balanceGet[i]?.intangibleAssets|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.intangibleAssets?(balance2[i]?.intangibleAssets|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="non_assets" *ngIf="isnoncurrent">
                            <td class="bor_bgnone" style="width: 20%;padding-left: 40px;">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/L/long-term-investments">Long Term
                                Investments</a>
                            </td>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.longTermInvestments?(balance?.longTermInvestments|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.longTermInvestments?(balanceGet[i]?.longTermInvestments|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.longTermInvestments?(balance2[i]?.longTermInvestments|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="non_assets" *ngIf="isnoncurrent">
                            <td class="bor_bgnone" style="width: 20%;padding-left: 40px;">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/O/other-non-current-assets">Other
                                Non-Current</a>
                            </td>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.otherAssets?(balance?.otherAssets|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.otherAssets?(balanceGet[i]?.otherAssets|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.otherAssets?(balance2[i]?.otherAssets|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 20%;">
                              <i class="fa fa-chevron-down icon_open" *ngIf="!isIcon3" (click)="totalLiabilities()"></i>
                              <i class="fa fa-chevron-up icon_open" *ngIf="isIcon3" (click)="totalLiabilities()"></i>
                              <a href="https://manicmarket.com/investor-dictionary/T/total-liabilities">
                                <h1> Total Liabilities</h1>
                              </a>
                            </th>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.totalLiab?(balance?.totalLiab|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.totalLiab?(balanceGet[i]?.totalLiab|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.totalLiab?(balance2[i]?.totalLiab|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="total_liabilities" *ngIf="isLiabilities">
                            <th style="width: 20%;">
                              <i class="fa fa-chevron-down icon_open" *ngIf="!isIcon4"
                                (click)="currentLiabilities()"></i>
                              <i class="fa fa-chevron-up icon_open" *ngIf="isIcon4" (click)="currentLiabilities()"></i>

                              <a href="https://manicmarket.com/investor-dictionary/C/current-liabilities">
                                <h2>Current Liabilities</h2>
                              </a>
                            </th>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.totalCurrentLiabilities?(balance?.totalCurrentLiabilities|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.totalCurrentLiabilities?(balanceGet[i]?.totalCurrentLiabilities|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.totalCurrentLiabilities?(balance2[i]?.totalCurrentLiabilities|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="current_liabilities" *ngIf="iscurrentLiabilities">
                            <td class="bor_bgnone" style="width: 20%;padding-left: 40px;">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/A/accounts-payable">Accounts
                                Payable</a>
                            </td>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.accountsPayable?(balance?.accountsPayable|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.accountsPayable?(balanceGet[i]?.accountsPayable|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.accountsPayable?(balance2[i]?.accountsPayable|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="current_liabilities" *ngIf="iscurrentLiabilities">
                            <td class="bor_bgnone" style="width: 20%;padding-left: 40px;">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/O/other-current-liabilities">Other
                                Current Liabilities</a>
                            </td>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.otherCurrentLiab?(balance?.otherCurrentLiab|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.otherCurrentLiab?(balanceGet[i]?.otherCurrentLiab|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.otherCurrentLiab?(balance2[i]?.otherCurrentLiab|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="total_liabilities" *ngIf="isLiabilities">
                            <th style="width: 20%;padding-left: 20px;">
                              <i class="fa fa-chevron-down icon_open" *ngIf="!isIcon5"
                                (click)="noncurrentLiabilities()"></i>
                              <i class="fa fa-chevron-up icon_open" *ngIf="isIcon5"
                                (click)="noncurrentLiabilities()"></i>

                              <a href="https://manicmarket.com/investor-dictionary/N/non-current-liabilities">
                                <h2>Non-Current Liabilities</h2>
                              </a>
                            </th>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.NonCurrentLiabilities?(balance?.NonCurrentLiabilities|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.NonCurrentLiabilities?(balanceGet[i]?.NonCurrentLiabilities|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.NonCurrentLiabilities?(balance2[i]?.NonCurrentLiabilities|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="non_liabilities" *ngIf="isnonLiabilities">
                            <td class="bor_bgnone" style="width: 20%; padding-left: 40px;">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/L/long-term-debt">Long Term Debt</a>
                            </td>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.longTermDebt?(balance?.longTermDebt|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.longTermDebt?(balanceGet[i]?.longTermDebt|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.longTermDebt?(balance2[i]?.longTermDebt|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="non_liabilities" *ngIf="isnonLiabilities">
                            <td class="bor_bgnone" style="width: 20%; padding-left: 40px;">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/O/other-non-current-liabilities">Other
                                Non-Current</a>
                            </td>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.otherLiab?(balance?.otherLiab|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.otherLiab?(balanceGet[i]?.otherLiab|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.otherLiab?(balance2[i]?.otherLiab|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 20%;">
                              <a href="https://manicmarket.com/investor-dictionary/E/equity">
                                <h1>Stockholder Equity</h1>
                              </a>
                            </th>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.totalStockholderEquity?(balance?.totalStockholderEquity|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.totalStockholderEquity?(balanceGet[i]?.totalStockholderEquity|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.totalStockholderEquity?(balance2[i]?.totalStockholderEquity|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 20%;">
                              <a href="https://manicmarket.com/investor-dictionary/N/net-tangible-assets-nta">
                                <h1>Net Tangible Assets</h1>
                              </a>
                            </th>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.netTangibleAssets?(balance?.netTangibleAssets|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.netTangibleAssets?(balanceGet[i]?.netTangibleAssets|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.netTangibleAssets?(balance2[i]?.netTangibleAssets|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 20%;">
                              <a href="https://manicmarket.com/investor-dictionary/S/shares-outstanding">
                                <h1>Shares Outstanding</h1>
                              </a>
                            </th>
                            <td width="20%" *ngFor="let balance of getdata;let i=index">
                              <span class="widthbox">
                                {{balance?.commonStock?(balance?.commonStock|AmountFormat:2):'N/A'}}</span>
                              <span class="widthbox" *ngIf="isSuperBalance">
                                {{balanceGet[i]?.commonStock?(balanceGet[i]?.commonStock|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="isSuperBal1"
                                [ngClass]="{'widthbox' : balance2[i]?.totalAssets}">
                                {{balance2[i]?.commonStock?(balance2[i]?.commonStock|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- BALANCE TABLE END -->
                      <div class="tab-content ptb" id="myTabContent">
                        <div class="tab-pane fade show active" id="bannual" role="tabpanel"
                          aria-labelledby="bannual-tab">
                          <div class="collapse_warp table-responsive">
                            <app-financial-data *ngIf="this.model?.company_finance?.BalanceSheet?.Annual"
                              [data]="this.model?.company_finance?.BalanceSheet?.Annual" PreFix="BalanceAnual">
                            </app-financial-data>
                          </div>
                        </div>
                        <div class="tab-pane fade " id="bquarterly" role="tabpanel" aria-labelledby="bquarterly-tab">
                          <div class="collapse_warp table-responsive">
                            <app-financial-data *ngIf="this.model?.company_finance?.BalanceSheet?.Quarterly"
                              [data]="this.model?.company_finance?.BalanceSheet?.Quarterly" PreFix="BalanceQuarterly">
                            </app-financial-data>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- BALANCE END -->

                    <!-- INCOME START -->
                    <div class="tab-pane  fade show active three_staptb" id="income" role="tabpanel"
                      aria-labelledby="income-tab" *ngIf="this.tab_Value == 'income-tab'">
                      <!-- INCOME TABLE START -->
                      <table class="table fainens_table new_sheet">
                        <thead class="year_headedata">
                          <tr>
                            <th width="20%"></th>
                            <th *ngFor="let income of getincomedata;let i=index" width="20%">
                              <span class="widthbox">{{income?.date | date:'yyyy'}}</span>
                              <span class="widthbox" *ngIf="this.incomeSym1 != null" (click)="clearbalance()">
                                <span class="bluebg">{{this.incomeSym1}} <i class="fa fa-close"></i></span>
                              </span>
                              <span class=" company_data2" *ngIf="isIncome1"
                                [ngClass]="{'widthbox' : incomeGet2[i]?.totalRevenue}">
                                <span class="bluebg" (click)="clearbala2()">{{this.incomeSym2}}<i
                                    class="fa fa-close"></i></span>
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th style="width: 20%;" role="button">
                              <h1>
                                <a href="https://manicmarket.com/investor-dictionary/R/revenue"
                                  style="color: inherit;">Revenue</a>
                              </h1>
                            </th>
                            <td width="20%" *ngFor="let income of getincomedata;let i=index">
                              <span class="widthbox">
                                {{income?.totalRevenue?(income?.totalRevenue|AmountFormat:2):''}}</span>
                              <span class="widthbox">
                                {{incomeGet[i]?.totalRevenue?(incomeGet[i]?.totalRevenue|AmountFormat:2):''}}</span>
                              <span class=" company_data2" [ngClass]="{'widthbox' : incomeGet2[i]?.totalRevenue}">
                                {{incomeGet2[i]?.totalRevenue?(incomeGet2[i]?.totalRevenue|AmountFormat:2):''}}</span>
                              <!-- <pre>{{incomeGet2[i]?.totalRevenue}}</pre> -->
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 20%;" role="button">
                              <a href="https://manicmarket.com/investor-dictionary/C/cost-of-revenue"
                                style="color: inherit;">
                                <h1>Cost Of Revenue</h1>
                              </a>
                            </th>
                            <td width="20%" *ngFor="let income of getincomedata;let i=index">
                              <span class="widthbox">
                                {{income?.costOfRevenue?(income?.costOfRevenue|AmountFormat:2):''}}</span>
                              <span class="widthbox">
                                {{incomeGet[i]?.costOfRevenue?(incomeGet[i]?.costOfRevenue|AmountFormat:2):''}}</span>
                              <span class=" company_data2" [ngClass]="{'widthbox' : incomeGet2[i]?.totalRevenue}">
                                {{incomeGet2[i]?.costOfRevenue?(incomeGet2[i]?.costOfRevenue|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 20%;" role="button">
                              <h1>
                                <a href="https://manicmarket.com/investor-dictionary/G/gross-profit"
                                  style="color: inherit;">Gross Profit</a>
                              </h1>
                            </th>
                            <td width="20%" *ngFor="let income of getincomedata;let i=index">
                              <span class="widthbox">
                                {{income?.grossProfit?(income?.grossProfit|AmountFormat:2):''}}</span>
                              <span class="widthbox">
                                {{incomeGet[i]?.grossProfit?(incomeGet[i]?.grossProfit|AmountFormat:2):''}}</span>
                              <span class=" company_data2" [ngClass]="{'widthbox' : incomeGet2[i]?.totalRevenue}">
                                {{incomeGet2[i]?.grossProfit?(incomeGet2[i]?.grossProfit|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 20%;" role="button">
                              <h1>
                                <a href="https://manicmarket.com/investor-dictionary/O/operating-income"
                                  style="color: inherit;">Operating Income</a>
                              </h1>
                            </th>
                            <td width="20%" *ngFor="let income of getincomedata;let i=index">
                              <span class="widthbox">
                                {{income?.OperatingExpenses?(income?.OperatingExpenses|AmountFormat:2):''}}</span>
                              <span class="widthbox">
                                {{incomeGet[i]?.OperatingExpenses?(incomeGet[i]?.OperatingExpenses|AmountFormat:2):''}}</span>
                              <span class=" company_data2" [ngClass]="{'widthbox' : incomeGet2[i]?.totalRevenue}">
                                {{incomeGet2[i]?.OperatingExpenses?(incomeGet2[i]?.OperatingExpenses|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 20%;" class="lavel_06">
                              <i class="fa fa-chevron-down icon_open" *ngIf="!Expense" (click)="operatingExpense()"></i>
                              <i class="fa fa-chevron-up icon_open" *ngIf="Expense" (click)="operatingExpense()"></i>
                              <a href="https://manicmarket.com/investor-dictionary/O/operating-expenses">
                                <h1>Operating Expense</h1>
                              </a>
                            </th>
                            <td width="20%" *ngFor="let income of getincomedata;let i=index">
                              <span class="widthbox"> {{income.sellingGeneralAdministrative +
                                income.researchDevelopment|AmountFormat:2}}</span>
                              <span class="widthbox">
                                {{incomeGet[i]?.sellingGeneralAdministrative?(incomeGet[i]?.sellingGeneralAdministrative|AmountFormat:2):''}}</span>
                              <span class=" company_data2" [ngClass]="{'widthbox' : incomeGet2[i]?.totalRevenue}">
                                {{incomeGet2[i]?.sellingGeneralAdministrative?(incomeGet2[i]?.sellingGeneralAdministrative|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="operating_expense" *ngIf="Expense">
                            <td class="bor_bgnone" style="width: 20%; padding-left: 20px;">
                              <a href="https://manicmarket.com/investor-dictionary/S/selling-general-administrative-sga"
                                class="short_nm"
                                style="color: inherit;">S<span>elling</span>G<span>eneral</span>&A<span>dministrative</span></a>
                            </td>
                            <td width="20%" *ngFor="let income of getincomedata;let i=index">
                              <span class="widthbox">
                                {{income?.sellingGeneralAdministrative?(income?.sellingGeneralAdministrative|AmountFormat:2):''}}</span>
                              <span class="widthbox">
                                {{incomeGet[i]?.sellingGeneralAdministrative?(incomeGet[i]?.sellingGeneralAdministrative|AmountFormat:2):''}}</span>
                              <span class=" company_data2" [ngClass]="{'widthbox' : incomeGet2[i]?.totalRevenue}">
                                {{incomeGet2[i]?.sellingGeneralAdministrative?(incomeGet2[i]?.sellingGeneralAdministrative|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="collapse operating_expense">
                            <td class="bor_bgnone" style="width: 20%; padding-left: 20px;">
                              <a href="https://manicmarket.com/investor-dictionary/R/research-development-rd"
                                style="color: inherit;">Research & Development</a>
                            </td>
                            <td width="20%" *ngFor="let income of getincomedata;let i=index">
                              <span class="widthbox">
                                {{income?.researchDevelopment?(income?.researchDevelopment|AmountFormat:2):''}}</span>
                              <span class="widthbox">
                                {{incomeGet[i]?.researchDevelopment?(incomeGet[i]?.researchDevelopment|AmountFormat:2):''}}</span>
                              <span class=" company_data2" [ngClass]="{'widthbox' : incomeGet2[i]?.totalRevenue}">
                                {{incomeGet2[i]?.researchDevelopment?(incomeGet2[i]?.researchDevelopment|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 20%;" role="button">
                              <a href="https://manicmarket.com/investor-dictionary/T/total-expenses"
                                style="color: inherit;">
                                <h1>Total Expense</h1>
                              </a>
                            </th>
                            <td width="20%" *ngFor="let income of getincomedata;let i=index">
                              <span class="widthbox">
                                {{income?.totalOperatingExpenses?(income?.totalOperatingExpenses|AmountFormat:2):''}}</span>
                              <span class="widthbox">
                                {{incomeGet[i]?.totalOperatingExpenses?(incomeGet[i]?.totalOperatingExpenses|AmountFormat:2):''}}</span>
                              <span class=" company_data2" [ngClass]="{'widthbox' : incomeGet2[i]?.totalRevenue}">
                                {{incomeGet2[i]?.totalOperatingExpenses?(incomeGet2[i]?.totalOperatingExpenses|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 20%;" role="button">
                              <h1>
                                <a href="https://manicmarket.com/investor-dictionary/N/net-income"
                                  style="color: inherit;">Net Income</a>
                              </h1>
                            </th>
                            <td width="20%" *ngFor="let income of getincomedata;let i=index">
                              <span class="widthbox"> {{income?.netIncome?(income?.netIncome |
                                AmountFormat:2):''}}</span>
                              <span class="widthbox">
                                {{incomeGet[i]?.netIncome?(incomeGet[i]?.netIncome | AmountFormat:2):''}}</span>
                              <span class=" company_data2" [ngClass]="{'widthbox' : incomeGet2[i]?.totalRevenue}">
                                {{incomeGet2[i]?.netIncome?(incomeGet2[i]?.netIncome | AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 20%;" role="button">
                              <h1>
                                <a href="https://manicmarket.com/investor-dictionary/E/earnings-per-share-eps"
                                  style="color: inherit;">Earnings Per Share</a>
                              </h1>
                            </th>
                            <td width="20%" *ngFor="let income of getincomedata;let i=index">
                              <span class="widthbox"> {{income.EarningsPerShare|AmountFormat:2}}</span>
                              <span class="widthbox">
                                {{incomeGet[i]?.EarningsPerShare?(incomeGet[i]?.EarningsPerShare|AmountFormat:2):''}}</span>
                              <span class=" company_data2" [ngClass]="{'widthbox' : incomeGet2[i]?.totalRevenue}">
                                {{incomeGet2[i]?.EarningsPerShare?(incomeGet2[i]?.EarningsPerShare|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- INCOME TABLE END m-->

                      <div class="tab-content ptb" id="imyTabContent">
                        <div class="tab-pane fade show active" id="iannual" role="tabpanel"
                          aria-labelledby="iannual-tab">
                          <div class="collapse_warp table-responsive">
                            <app-financial-data *ngIf="this.model?.company_finance?.IncomeStatement?.Annual"
                              [data]="this.model?.company_finance?.IncomeStatement?.Annual"
                              PreFix="IncomeStatementAnnual"></app-financial-data>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="iquarterly" role="tabpanel" aria-labelledby="iquarterly-tab">
                          <div class="collapse_warp table-responsive">
                            <app-financial-data *ngIf="this.model?.company_finance?.IncomeStatement?.Quarterly"
                              [data]="this.model?.company_finance?.IncomeStatement?.Quarterly"
                              PreFix="IncomeStatementQuarterly"></app-financial-data>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- INCOME END -->

                    <!-- CASHFLOW START -->
                    <div class="tab-pane fade three_staptb" id="cashflow" role="tabpanel" aria-labelledby="cashflow-tab"
                      *ngIf="this.tab_Value == 'cashflow-tab'">
                      <!-- CASH FLOW TABLE START -->
                      <table class="table fainens_table new_sheet">
                        <thead class="year_headedata">
                          <tr>
                            <th width="20%"></th>
                            <th *ngFor="let cashflow of getcashdata;let i=index" width="20%">
                              <span class="widthbox"> {{cashflow?.date | date:'yyyy'}} </span>
                              <span class="widthbox" *ngIf="this.cashsym1 != null">
                                <span class="bluebg" (click)="clearbalance()">{{this.cashsym1}}<i
                                    class="fa fa-close"></i></span>
                              </span>
                              <span class=" company_data2" *ngIf="iscashFlow1"
                                [ngClass]="{'widthbox' : incomeGet2[i]?.totalRevenue}">
                                <span class="bluebg" (click)="clearbala2()">{{this.cashsym2}}<i
                                    class="fa fa-close"></i></span>
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="deta_lavel_first">
                            <th style="width: 20%;" class="lavel_01 " role="button">
                              <h1>
                                <a style="color: inherit;"
                                  href="https://manicmarket.com/investor-dictionary/O/operating-cash-flow-ocf">Operating
                                  Cash Flow</a>
                              </h1>
                            </th>
                            <td width="20%" *ngFor="let cashflow of getcashdata;let i=index">
                              <span class="widthbox">
                                {{cashflow?.totalCashFromOperatingActivities?(cashflow?.totalCashFromOperatingActivities|AmountFormat:2):''}}</span>
                              <span class="widthbox" (click)="clearbalance()">
                                {{cashflowGet[i]?.totalCashFromOperatingActivities?(cashflowGet[i]?.totalCashFromOperatingActivities|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="iscashFlow1"
                                [ngClass]="{'widthbox' : cashflowGet2[i]?.FreeCashFlow}">
                                {{cashflowGet2[i]?.totalCashFromOperatingActivities?(cashflowGet2[i]?.totalCashFromOperatingActivities|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 20%;" class="lavel_01 " role="button">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/I/investing-cash-flow">
                                <h1>Investing Cash Flow</h1>
                              </a>
                            </th>
                            <td width="20%" *ngFor="let cashflow of getcashdata;let i=index">
                              <span class="widthbox">
                                {{cashflow?.totalCashflowsFromInvestingActivities?(cashflow?.totalCashflowsFromInvestingActivities|AmountFormat:2):''}}</span>
                              <span class="widthbox" *ngIf="iscashFlow">
                                {{cashflowGet[i]?.totalCashflowsFromInvestingActivities?(cashflowGet[i]?.totalCashflowsFromInvestingActivities|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="iscashFlow1"
                                [ngClass]="{'widthbox' : cashflowGet2[i]?.FreeCashFlow}">
                                {{cashflowGet2[i]?.totalCashflowsFromInvestingActivities?(cashflowGet2[i]?.totalCashflowsFromInvestingActivities|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th style="width: 20%;" class="lavel_01 " role="button">
                              <a style="color: inherit;"
                                href="https://manicmarket.com/investor-dictionary/F/financing-cash-flow">
                                <h1>Financing Cash Flow</h1>
                              </a>
                            </th>
                            <td width="20%" *ngFor="let cashflow of getcashdata;let i=index">
                              <span class="widthbox">
                                {{cashflow?.totalCashFromFinancingActivities?(cashflow?.totalCashFromFinancingActivities|AmountFormat:2):''}}</span>
                              <span class="widthbox" *ngIf="iscashFlow">
                                {{cashflowGet[i]?.totalCashFromFinancingActivities?(cashflowGet[i]?.totalCashFromFinancingActivities|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="iscashFlow1"
                                [ngClass]="{'widthbox' : cashflowGet2[i]?.FreeCashFlow}">
                                {{cashflowGet2[i]?.totalCashFromFinancingActivities?(cashflowGet2[i]?.totalCashFromFinancingActivities|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="border-bottom">
                            <th style="width: 20%;" class="lavel_01 " role="button">
                              <h1>
                                <a style="color: inherit;"
                                  href="https://manicmarket.com/investor-dictionary/C/capital-expenditure-capex">Capital
                                  Expenditure</a>
                              </h1>
                            </th>
                            <td width="20%" *ngFor="let cashflow of getcashdata;let i=index">
                              <span class="widthbox">
                                {{cashflow?.capitalExpenditures?(cashflow?.capitalExpenditures|AmountFormat:2):''}}</span>
                              <span class="widthbox" *ngIf="iscashFlow">
                                {{cashflowGet[i]?.capitalExpenditures? (cashflowGet[i]?.capitalExpenditures |
                                AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="iscashFlow1"
                                [ngClass]="{'widthbox' : cashflowGet2[i]?.FreeCashFlow}">
                                {{cashflowGet2[i]?.capitalExpenditures?(cashflowGet2[i]?.capitalExpenditures|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                          <tr class="border-bottom">
                            <th style="width: 20%;" class="lavel_01 " role="button">
                              <h1>
                                <a style="color: inherit;"
                                  href="https://manicmarket.com/investor-dictionary/F/free-cash-flow-fcf">Free Cash
                                  Flow</a>
                              </h1>
                            </th>
                            <td width="20%" *ngFor="let cashflow of getcashdata;let i=index">
                              <span class="widthbox">
                                {{cashflow?.FreeCashFlow?(cashflow?.FreeCashFlow|AmountFormat:2):''}}</span>
                              <span class="widthbox" *ngIf="iscashFlow">
                                {{cashflowGet[i]?.FreeCashFlow?(cashflowGet[i]?.FreeCashFlow|AmountFormat:2):''}}</span>
                              <span class=" company_data2" *ngIf="iscashFlow1"
                                [ngClass]="{'widthbox' : cashflowGet2[i]?.FreeCashFlow}">
                                {{cashflowGet2[i]?.FreeCashFlow?(cashflowGet2[i]?.FreeCashFlow|AmountFormat:2):''}}</span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- CASH FLOW TABLE START -->
                      <div class="tab-content ptb" id="cmyTabContent">
                        <div class="tab-pane fade show active" id="cannual" role="tabpanel"
                          aria-labelledby="cannual-tab">
                          <div class="collapse_warp table-responsive">
                            <app-financial-data *ngIf="this.model?.company_finance?.CashFlow?.Annual"
                              [data]="this.model?.company_finance?.CashFlow?.Annual" PreFix="CashFlowAnnual">
                            </app-financial-data>
                          </div>
                        </div>
                        <div class="tab-pane fade" id="cquarterly" role="tabpanel" aria-labelledby="cquarterly-tab">
                          <div class="collapse_warp table-responsive">
                            <app-financial-data *ngIf="this.model?.company_finance?.CashFlow?.Quarterly"
                              [data]="this.model?.company_finance?.CashFlow?.Quarterly" PreFix="CashFlowQuarterly">
                            </app-financial-data>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- CASHFLOW  END -->
                  </div>
                </div>

                <!-- ABOUT STRAT -->
                <div class="tab-pane fade" id="about" role="tabpanel" aria-labelledby="about-tab"
                  *ngIf="this.parentTabValue == 4">
                  <div class="annual_reportbtn add_watltbtn watch_btnpanel1">
                    <button type="button" class="btn btn-outline-success" (click)="annualClick()">
                      Annual Reports</button>
                    <ul class="watchlistname_list" *ngIf="annualReport">
                      <li *ngFor="let list of mydata?.annual_report; let i = index"
                        (click)="gotoAnnualReport(this.mydata.annual_report_file , list)">
                        <a href="javascript:void(0);">{{list}}</a>
                      </li>
                    </ul>
                  </div>
                  <table class="table table-bordered">
                    <tr >
                      <th colspan="2">
                        <span class="logo_name"> {{this.mydata?.securityName}} </span>
                      </th>
                    </tr>
                    <tr>
                      <span class="company_logo">
                        <img [src]="this.mydata?.symbol.logo" alt=""/>
                      </span>
                    </tr>
                    <tr>
                      <th>
                        Name:
                      </th>
                      <td>
                        {{this.mydata?.companyName}}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Industry:
                      </th>
                      <td>
                        {{this.mydata?.industry}}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Sector:
                      </th>
                      <td>
                        {{this.mydata?.sector}}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Description:
                      </th>
                      <td>
                        {{this.mydata?.description}}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        CEO:
                      </th>
                      <td>
                        {{this.mydata?.CEO}}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Employees:
                      </th>
                      <td>
                        {{this.mydata?.employees | number}}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Address:
                      </th>
                      <td>
                        {{this.mydata?.address}} {{this.mydata?.address2}}
                        <br />
                        {{this.mydata?.city}} {{this.mydata?.state}} {{this.mydata?.zipCode}}
                        <br />
                        {{this.mydata?.country}}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Phone:
                      </th>
                      <td>
                        {{this.mydata?.phone}}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        Website:
                      </th>
                      <td>
                        <a target="_blank" [href]="this.mydata?.website">
                          {{this.mydata?.website.slice(12)}}
                        </a>
                      </td>
                    </tr>
                  </table>
                  <tr>
                    <th class="Key_leader_css">Key Leaders:</th>
                  </tr>
                  <table class="table borderless">
                    <thead>
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Title</th>
                        <th class="text-right">Pay</th>
                        <th class="text-right">Age</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let keyItem of keyitem">
                        <td>{{keyItem?.name}}</td>
                        <td>{{keyItem?.title}}</td>
                        <td align="right">
                          {{keyItem?.pay?(keyItem?.pay | AmountFormat: 2):'N/A'}}
                        </td>
                        <td align="right">
                          {{keyItem?.age? ""+ (keyItem?.age | number : ''):'N/A'}}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!-- ABOUT END -->

                <!-- CHART STRAT -->
                <div class="tab-pane fade" id="chart" role="tabpanel" aria-labelledby="chart-tab">
                  <div class="cp_chart">
                    <!-- TradingView Widget BEGIN -->
                    <div class="tradingview-widget-container">
                      <div id="tradingview_ceb0f2"></div>
                    </div>
                    <!-- TradingView Widget END -->
                  </div>
                </div>
                <!-- CHART END -->
              </div>
            </div>
          </div>

          <div class="col-md-4 hhh" *ngIf="this.showSimilarDiv">
            <h4 class="symbol_similar" *ngIf="similarSocketdata?.length > 0">
              <strong>Similar To {{this.SymbolSocketdata?.symbol?.symbol
                ?this.SymbolSocketdata?.symbol?.symbol:'N/A'}}:</strong>
            </h4>
            <table class="table table_similar" *ngIf="yahooSimilar">
              <tbody>
                <tr *ngFor="let similarItem of similarSocketdata">
                  <th>
                    <a style="cursor: pointer;text-decoration: none;color: #00389d;"
                      [routerLink]="['/company-profile', similarItem?.symbol]" (click)="similarClick()">
                      <strong class="d-block">{{similarItem?.symbol}}</strong>
                    </a>
                    <small>{{similarItem?.companyName}}</small>
                  </th>
                  <td>
                    <span
                      [ngClass]="{'text-success': (similarItem?.changePercent >  0), 'text-warning': (similarItem?.changePercent <  0)}"
                      *ngIf="this.News_data.length > 0">
                      {{similarItem?.changePercent > 0? '': ''}} {{similarItem?.changePercent > 0? '+': ''}}
                      {{(similarItem?.changePercent || similarItem?.changePercent == 0) ? ((similarItem?.changePercent) | number : '1.2-2' ): 'N/A'}}%
                    </span>
                    <span
                      [ngClass]="{'text-success': (similarItem?.changePercent >  0), 'text-warning': (similarItem?.changePercent <  0)}"
                      *ngIf="this.News_data.length == 0">
                      {{similarItem?.changePercent > 0? '': ''}} {{similarItem?.changePercent > 0? '+': ''}}
                      {{(similarItem?.changePercent || similarItem?.changePercent == 0) ? ((similarItem?.changePercent )
                      | number : '1.2-2' ): 'N/A'}}%
                    </span>

                  </td>
                  <td>
                    <span
                      [ngClass]="{'text-success': (similarItem?.change >  0), 'text-warning': (similarItem?.change <  0)}">{{similarItem?.change?
                      "$"+ (similarItem?.change | number : '.2-2'):'N/A'}}
                    </span>
                  </td>
                  <td style="color: #444242;">
                    <span>{{similarItem?.latestPrice?
                      "$"+ (similarItem?.latestPrice | number : '.2-2'):'N/A'}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Api table data show end -->
            <!-- yahoo websocket data show table start similar table -->
            <table class="table table_similar" *ngIf="yahooSimilar2">
              <tbody>
                <tr *ngFor="let similarItem of similarSocketdata">
                  <th>
                    <a style="cursor: pointer;text-decoration: none;color: #00389d;"
                      [routerLink]="['/company-profile', similarItem?.symbol]" (click)="similarClick()">
                      <strong class="d-block">{{similarItem?.symbol}}</strong>
                    </a>
                    <small>{{similarItem?.companyName}}</small>
                  </th>
                  <td>
                    <span
                      [ngClass]="{'text-success': (similarItem?.changePercent >  0), 'text-warning': (similarItem?.changePercent <  0)}"
                      *ngIf="this.News_data.length > 0 && yahooData[similarItem?.symbol]?.marketHours != '1'">
                      {{similarItem?.changePercent > 0? '': ''}} {{similarItem?.changePercent > 0? '+': ''}}
                      {{(similarItem?.changePercent || similarItem?.changePercent == 0) ? ((similarItem?.changePercent) | number : '1.2-2' ): 'N/A'}}%
                    </span>
                    <span
                      [ngClass]="{'text-success': (yahooData[similarItem?.symbol]?.changePercent >  0), 'text-warning': (yahooData[similarItem?.symbol]?.changePercent <  0)}" *ngIf="this.News_data.length > 0 && yahooData[similarItem?.symbol]?.marketHours == '1'">
                      {{yahooData[similarItem?.symbol]?.changePercent > 0? '': ''}} {{yahooData[similarItem?.symbol]?.changePercent > 0? '+': ''}}
                      {{(yahooData[similarItem?.symbol]?.changePercent || yahooData[similarItem?.symbol]?.changePercent == 0) ? ((yahooData[similarItem?.symbol]?.changePercent) | number : '1.2-2' ): 'N/A'}}%
                    </span>
                  </td>
                  
                  <td>
                    <span [ngClass]="{'text-success': (similarItem?.change >  0), 'text-warning': (similarItem?.change <  0)}" *ngIf="yahooData[similarItem?.symbol]?.marketHours != '1'">
                      {{similarItem?.change? "$"+ (similarItem?.change | number : '.2-2'):'N/A'}}
                    </span>
                    <span [ngClass]="{'text-success': (yahooData[similarItem?.symbol]?.change >  0), 'text-warning': (yahooData[similarItem?.symbol]?.change <  0)}" *ngIf="yahooData[similarItem?.symbol]?.marketHours == '1'">
                      {{yahooData[similarItem?.symbol] ? "$"+ (yahooData[similarItem?.symbol]?.change | number : '.2-2'):'N/A'}}
                    </span>
                  </td>
                  <td style="color: #444242;">
                    <span *ngIf="yahooData[similarItem?.symbol]?.marketHours != '1'">
                      {{similarItem?.latestPrice? "$"+ (similarItem?.latestPrice | number : '.2-2'):'N/A'}}
                    </span>
                    <span *ngIf="yahooData[similarItem?.symbol]?.marketHours == '1'">
                      {{yahooData[similarItem?.symbol]?.price? "$"+ (yahooData[similarItem?.symbol]?.price | number : '.2-2'):'N/A'}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- yahoo websocket data show table end -->
          </div>

          <div class="col-md-4 hhh" *ngIf="!this.showSimilarDiv && this.parentTabValue !== 2">
            <h4 class="symbol_similar" *ngIf="similarSocketdata.length > 0">
              <strong>Similar To {{this.SymbolSocketdata?.symbol ?this.SymbolSocketdata?.symbol:'N/A'}}:</strong>
            </h4>
            <!-- Api table data show start similar table -->
            <table class="table table_similar" *ngIf="yahooSimilar">
              <tbody>
                <tr *ngFor="let similarItem of similarSocketdata">
                  <th>
                    <a style="cursor: pointer;text-decoration: none;color: #00389d;"
                      [routerLink]="['/company-profile', similarItem?.symbol]" (click)="similarClick()">
                      <strong class="d-block">{{similarItem?.symbol}}</strong>
                    </a>
                    <small>{{similarItem?.companyName}}</small>
                  </th>
                  <td>
                    <span
                      [ngClass]="{'text-success': (similarItem?.changePercent >  0), 'text-warning': (similarItem?.changePercent <  0)}"
                      *ngIf="this.News_data.length > 0">
                      {{similarItem?.changePercent > 0? '': ''}} {{similarItem?.changePercent > 0? '+': ''}}
                      {{(similarItem?.changePercent || similarItem?.changePercent == 0) ? ((similarItem?.changePercent) | number : '1.2-2' ): 'N/A'}}%
                    </span>
                    <span
                      [ngClass]="{'text-success': (similarItem?.changePercent >  0), 'text-warning': (similarItem?.changePercent <  0)}"
                      *ngIf="this.News_data.length == 0">
                      {{similarItem?.changePercent > 0? '': ''}} {{similarItem?.changePercent > 0? '+': ''}}
                      {{(similarItem?.changePercent || similarItem?.changePercent == 0) ? ((similarItem?.changePercent )
                      | number : '1.2-2' ): 'N/A'}}%
                    </span>
                  </td>
                  <td>
                    <span
                      [ngClass]="{'text-success' : (similarItem?.change >  0), 'text-warning': (similarItem?.change <  0)}">{{similarItem?.change?
                      "$"+ (similarItem?.change | number : '.2-2'):'N/A'}}
                    </span>
                  </td>
                  <td style="color: #444242;">
                    <span>{{similarItem?.latestPrice?
                      "$"+ (similarItem?.latestPrice | number : '.2-2'):'N/A'}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- Api table data show end -->

            <!-- yahoo websocket data show table start similar table -->
            <table class="table table_similar" *ngIf="yahooSimilar2">
              <tbody>
                <tr *ngFor="let similarItem of similarSocketdata">
                  <th>
                    <a style="cursor: pointer;text-decoration: none;color: #00389d;"
                      [routerLink]="['/company-profile', similarItem?.symbol]" (click)="similarClick()">
                      <strong class="d-block">{{similarItem?.symbol}}</strong>
                    </a>
                    <small>{{similarItem?.companyName}}</small>
                  </th>
                  <td>
                    <span
                      [ngClass]="{'text-success': (similarItem?.changePercent >  0), 'text-warning': (similarItem?.changePercent <  0)}"
                      *ngIf="this.News_data.length > 0 && yahooData[similarItem?.symbol]?.marketHours != '1'">
                      {{similarItem?.changePercent > 0? '': ''}} {{similarItem?.changePercent > 0? '+': ''}}
                      {{(similarItem?.changePercent || similarItem?.changePercent == 0) ? ((similarItem?.changePercent) | number : '1.2-2' ): 'N/A'}}%
                    </span>
                    <span
                      [ngClass]="{'text-success': (yahooData[similarItem?.symbol]?.changePercent >  0), 'text-warning': (yahooData[similarItem?.symbol]?.changePercent <  0)}" *ngIf="this.News_data.length > 0 && yahooData[similarItem?.symbol]?.marketHours == '1'">
                      {{yahooData[similarItem?.symbol]?.changePercent > 0? '': ''}} {{yahooData[similarItem?.symbol]?.changePercent > 0? '+': ''}}
                      {{(yahooData[similarItem?.symbol]?.changePercent || yahooData[similarItem?.symbol]?.changePercent == 0) ? ((yahooData[similarItem?.symbol]?.changePercent) | number : '1.2-2' ): 'N/A'}}%
                    </span>
                  </td>
                  
                  <td>
                    <span [ngClass]="{'text-success': (similarItem?.change >  0), 'text-warning': (similarItem?.change <  0)}" *ngIf="yahooData[similarItem?.symbol]?.marketHours != '1'">
                      {{similarItem?.change? "$"+ (similarItem?.change | number : '.2-2'):'N/A'}}
                    </span>
                    <span [ngClass]="{'text-success': (yahooData[similarItem?.symbol]?.change >  0), 'text-warning': (yahooData[similarItem?.symbol]?.change <  0)}" *ngIf="yahooData[similarItem?.symbol]?.marketHours == '1'">
                      {{yahooData[similarItem?.symbol]?.change ? "$"+ (yahooData[similarItem?.symbol]?.change | number : '.2-2'):'N/A'}}
                    </span>
                  </td>
                  <td style="color: #444242;">
                    <span *ngIf="yahooData[similarItem?.symbol]?.marketHours != '1'">
                      {{similarItem?.latestPrice? "$"+ (similarItem?.latestPrice | number : '.2-2'):'N/A'}}
                    </span>
                    <span *ngIf="yahooData[similarItem?.symbol]?.marketHours == '1'">
                      {{yahooData[similarItem?.symbol]?.price? "$"+ (yahooData[similarItem?.symbol]?.price | number : '.2-2'):'N/A'}}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <!-- yahoo websocket data show table end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal Button  -->
<div class="message_btn_fx">
  <button class="message_btn" type="button" data-toggle="modal" (click)="feedbackInput = '';"
    data-target="#message_bottom" (click)="gotoLoginPage()">
    <img src="../../assets/img/asset.png" alt="">
  </button>
</div>

<!-- Modal Content -->
<div class="modal fade add_queto_modal message_bottom" id="message_bottom" tabindex="-1" role="dialog"
  aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-md mob" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h2>We’d love to hear from you as we continue to improve our product!</h2>
        <button type="button" (click)="this.feedbackInput=''" class="close" data-dismiss="modal" aria-label="Close">
          <span>
            <i class="fa fa-times" aria-hidden="true"></i>
          </span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <form> -->
        <div class="form_inner">
          <textarea [(ngModel)]="feedbackInput" class="form-control"
            placeholder="Please let us know your thoughts..."></textarea>
        </div>
        <div class="add_termactin">
          <button type="button" data-dismiss="modal" [disabled]="!feedbackInput" aria-label="Close"
            (click)="saveFeedback()" class="btn btn-success">Submit</button>
          <button type="button" data-dismiss="modal" aria-label="Close" (click)="this.feedbackInput=''"
            class="btn btn-secondary">Cancel</button>
        </div>
        <!-- </form> -->
      </div>
    </div>
  </div>
</div>
<div *ngIf="windowchange"></div>

